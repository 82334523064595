import React from "react"
import styled, { keyframes } from "styled-components"
import Icon from "../Icon/Icon"

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

const StyledSpinner = styled(Icon).attrs({
  name: "spinner",
})`
  animation: ${spin} 1s linear infinite;

  > circle {
    animation: ${dash} 1.5s linear infinite;
  }
`

function Spinner(props) {
  return <StyledSpinner {...props} />
}

export default Spinner
