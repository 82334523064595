import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { DEFAULT_SHIPPING_INTERVAL } from "../../constants"
import { getAttributeByKey } from "../../utils/getLineItemAttributes"
import { formatPrice } from "../../utils/money"
import ButtonAddToCart from "../Buttons/ButtonAddToCart"

const StyledUpsellItemWrap = styled.section`
  display: flex;
  width: 100%;
  margin: 30px 0px;
  gap: 2rem;
  border-radius: 5px;
  padding: 20px;
  align-items: flex-start;

  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  > img {
    width: 68px;
    height: 68px;
  }

  @media only screen and (min-width: 1024px) {
    min-width: 390px;
  }
`

const StyledUpsellItemInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;

  > h2 {
    color: var(--color-irish-wolfhound);
    font-weight: bold;
    font-size: 20px;
    margin: 0px;
  }
`

const StyledPriceAndButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

const StyledPrice = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  align-items: center;
  flex-direction: column;
  font-size: 18px;

  p {
    margin: 0;
    font-weight: var(--font-bold);
  }

  p:first-child {
    line-height: 1;
    color: ${({ hasStrikeThrough }) =>
      hasStrikeThrough ? "var(--color-pointer)" : "var(--color-great-dane)"};
    text-decoration: ${({ hasStrikeThrough }) =>
      hasStrikeThrough ? "line-through" : "none"};
    font-weight: ${({ hasStrikeThrough }) =>
      hasStrikeThrough ? "var(--font-normal)" : "var(--font-bold)"};
  }

  @media only screen and (min-width: 30em) {
    flex-direction: row;
  }

  @media only screen and (min-width: 48em) {
    flex-direction: column;
  }
`

function UpsellModalItem({
  id,
  image,
  price,
  displayName,
  metafields,
  storefrontId,
  shopifyId,
  isSubscriptionUpsell,
  purchaseType,
  product,
}) {
  const getAttributeValue = getAttributeByKey(metafields)

  const formattedPrice = formatPrice(price)
  const formattedDiscountPrice = formatPrice(
    getAttributeValue("discount_variant_price")
  )

  let trimmedDisplayName

  if (displayName.includes("- Default Title")) {
    trimmedDisplayName = displayName
      .replace("Dog Supplements - Default Title", "")
      .trim()
  } else if (displayName.includes("Treats")) {
    trimmedDisplayName = displayName
      .replace("Superfood Dog Treats With Koji (5oz) - ", "")
      .trim()
  }

  return (
    <StyledUpsellItemWrap key={id}>
      {image && (
        <GatsbyImage
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={displayName}
        />
      )}
      <StyledUpsellItemInner>
        <h2>{trimmedDisplayName}</h2>
        <StyledPriceAndButtons>
          <StyledPrice hasStrikeThrough={isSubscriptionUpsell}>
            <p>{formattedPrice}</p>
            {isSubscriptionUpsell && <p>{formattedDiscountPrice}</p>}
          </StyledPrice>
          <ButtonAddToCart
            showQuantity={false}
            text="Add to order"
            afterAddedText="✔︎ Added"
            variant={{ metafields, storefrontId, price, shopifyId }}
            product={product}
            purchaseType={purchaseType}
            interval={DEFAULT_SHIPPING_INTERVAL}
            size="small"
            hasViewItemDataLayer={false}
          />
        </StyledPriceAndButtons>
      </StyledUpsellItemInner>
    </StyledUpsellItemWrap>
  )
}

export default UpsellModalItem
