export function variantForOptions(product, options) {
  if (!Object.keys(options).length) {
    return product.variants[0]
  }

  return product?.variants.find(variant => {
    return variant.selectedOptions.every(selectedOption => {
      return options[selectedOption.name] === selectedOption.value.valueOf()
    })
  })
}
