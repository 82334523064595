import React from "react"
import styled from "styled-components"
import { CONTENT_POSITION_DICTIONARY } from "../../constants"
import { columns } from "../../utils/columns"

// group together common styles for positioning our pseudo element that serves as our background image overlay
const setOverlay = () => `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  content: "";
`

const setColumnSizeWhenSupported = columnSize =>
  columnSize && `max-width: ${columns(columnSize)}px;`

export const StyledHeroContentInner = styled.div`
  > .wp-block-image {
    > figure {
      display: block !important;
      float: none;
    }
  }
`

export const StyledHeroContent = styled.div`
  position: relative;

  &.has-parent-block-wildearth-hero-split {
    padding: 24px;

    ${({ verticalAlignment }) => verticalAlignment && `display: flex;`}

    ${({ verticalAlignment }) =>
      verticalAlignment &&
      `align-items: ${CONTENT_POSITION_DICTIONARY[verticalAlignment][0]};`}

    ${({ backgroundColor }) =>
      backgroundColor && `background-color: ${backgroundColor}`};

    > div {
      margin: 0 auto;
      ${({ verticalAlignment }) => verticalAlignment && `flex: 1;`}
      ${({ columnSize }) => setColumnSizeWhenSupported(columnSize)}

      ${({ backgroundColor }) =>
        backgroundColor && `background-color: ${backgroundColor}`};
    }
  }

  &:not(.has-parent-block-wildearth-hero-split) {
    padding: 3rem 20px;
    flex: 1;

    ${({ columnSize }) => setColumnSizeWhenSupported(columnSize)}

    &:before {
      opacity: ${({ dimRatio }) => dimRatio && dimRatio * 0.01};
      ${({ backgroundColor }) => backgroundColor && setOverlay()}
      ${({ backgroundColor }) =>
        backgroundColor && `background-color: ${backgroundColor};`}
    }

    @media only screen and (min-width: 48em) {
      padding: ${({ backgroundColor }) => backgroundColor && `3rem`};
    }
  }
`

function HeroContent({ children, ...rest }) {
  return (
    <StyledHeroContent {...rest}>
      <StyledHeroContentInner>{children}</StyledHeroContentInner>
    </StyledHeroContent>
  )
}

export default HeroContent
