import { em } from "polished"
import React from "react"
import styled from "styled-components"

export const StyledSelect = styled.label`
  margin-bottom: ${em(16)};
  display: inline-block;
`

export const StyledFieldLabelWrap = styled.div`
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === "horizontal" ? "row" : "column"};
  flex-wrap: wrap;
  font-size: 14px;

  @media only screen and (min-width: 30em) {
    font-size: 16px;
  }
`

export const StyledFieldLabelMessage = styled.span`
  font-weight: var(--font-normal);
  color: ${({ textColor }) => textColor};
  margin-right: 0.5em;
`

export const StyledFieldSelect = styled.select`
  touch-action: manipulation;
  font-family: var(--body-font-stack);
  margin: 0;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10L12 15L17 10H7Z' fill='%2313294B'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right -5px center;
  border-radius: 0;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${({ textColor }) => textColor};
  padding: 0 1em 0 0;
  text-indent: 0.01px;
  cursor: pointer;
  vertical-align: middle;
  max-width: 100%;
  font-size: 100%;
  color: ${({ textColor }) => textColor};
  font-weight: var(--font-bold);
`

export function Select({ orientation, textColor, options, label, ...rest }) {
  const fieldOptions = React.Children.toArray(
    options.map(option => <option {...option} />)
  )
  return (
    <StyledSelect>
      <StyledFieldLabelWrap orientation={orientation}>
        <StyledFieldLabelMessage textColor={textColor}>
          {label}
        </StyledFieldLabelMessage>
        <StyledFieldSelect textColor={textColor} {...rest}>
          {fieldOptions}
        </StyledFieldSelect>
      </StyledFieldLabelWrap>
    </StyledSelect>
  )
}
