import React from "react"
import {
  StyledDropdownSelect,
  StyledDropdownWrap,
  StyledFormFieldPill,
  StyledFormFieldPillGroup,
  StyledFormFieldPillInput,
  StyledFormFieldPillLabel,
  StyledPillFormFieldGroup,
} from "./StyledFormFields"

function ProductOptions({ availableOptions, options, handleChangeOption }) {
  return Object.entries(availableOptions).map(
    ([label, { values, position }]) => {
      if (position === 1) {
        return (
          <StyledFormFieldPillGroup key={label}>
            <legend>{label}</legend>
            {values.map(value => {
              return (
                <StyledFormFieldPill
                  key={value}
                  isSelected={options[label] === value}
                >
                  <StyledFormFieldPillInput
                    type="radio"
                    autoComplete="off"
                    name={label}
                    value={value}
                    onChange={handleChangeOption}
                    checked={options[label] === value}
                  />
                  <StyledFormFieldPillLabel>{value}</StyledFormFieldPillLabel>
                </StyledFormFieldPill>
              )
            })}
          </StyledFormFieldPillGroup>
        )
      }

      return (
        <StyledPillFormFieldGroup key={label}>
          <legend>{label}</legend>
          <StyledDropdownWrap>
            <StyledDropdownSelect onChange={handleChangeOption} name={label}>
              {values.map(value => {
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                )
              })}
            </StyledDropdownSelect>
          </StyledDropdownWrap>
        </StyledPillFormFieldGroup>
      )
    }
  )
}

export default ProductOptions
