import styled from "styled-components"

export const StyledAnchor = styled.a`
  white-space: nowrap;
  font-size: 1rem;
  font-weight: var(--font-bold);
  display: block;
  text-decoration: none;
  color: var(--color-great-dane);
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
  position: relative;

  svg {
    display: none;
    &:only-child {
      display: block;
    }
  }

  @media only screen and (min-width: 64em) {
    color: #ffffff;
    cursor: pointer;

    > span + svg {
      margin-left: 10px;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      transition: transform 0.15s ease-out;

      &:only-child {
        display: inline;
      }
    }
  }
`

export const StyledSubMenu = styled.div`
  @media only screen and (min-width: 64em) {
    border: 0;
    position: absolute;
    display: none;
    border-radius: 0 0 6px 6px;
    padding: 0;
    left: 0;
    box-shadow: 0 5px 6px 0 rgb(0 0 0 / 16%);
    background-color: #ffffff;
    overflow: hidden;
  }
`

export const StyledSubMenuContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  @media only screen and (min-width: 64em) {
    li:not(:only-child) {
      margin: 0;
    }
  }
`

export const StyledSubMenuItem = styled.li`
  @media only screen and (min-width: 64em) {
    padding: 0 1rem 0 1rem;
    transition: background-color 0.5s ease;
    min-width: 300px;

    &:not(:last-child) {
      ${StyledAnchor} {
        border-bottom: 1px solid #d2e0ed;
      }
    }

    ${StyledAnchor} {
      border-bottom: 0;
      position: relative;
      width: 100%;
      color: var(--color-irish-wolfhound);

      &:hover,
      &:focus {
        &::before {
          right: -5px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cpath fill-rule='evenodd' fill='%23004986' d='M91.5 6494l-1-1 5.6-5.8H84v-1.4h12.1l-5.7-5.7 1.1-1.1 7.5 7.5-7.5 7.5' transform='translate(-84 -6479)'/%3E%3C/svg%3E");
        right: 0;
        top: 50%;
        height: 15px;
        width: 15px;
        transition: right 0.5s ease;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
`

export const StyledMenuItem = styled.li`
  line-height: 73px;

  ${StyledSubMenu} {
    padding-left: 2rem;
  }

  &.m-is-dropdown {
    ${StyledSubMenu} {
      opacity: 0;
    }
    ${StyledSubMenu}[aria-expanded="false"] {
      display: none;
    }

    > ${StyledAnchor} {
      color: var(--color-staffie);
    }

    span {
      width: 100%;
      display: inline-block;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      transition: transform 0.15s ease-out;
      margin-left: -12px;
    }

    ${({ isAriaExpanded }) =>
      isAriaExpanded &&
      `> ${StyledAnchor} svg {
      transform: rotateZ(180deg);
    }`}

    ${StyledSubMenu}[aria-expanded="true"] {
      display: block;
      opacity: 1;
    }
  }

  @media only screen and (min-width: 64em) {
    line-height: 80px;

    &:hover,
    &:focus-within {
      > ${StyledAnchor} {
        svg {
          transform: rotateZ(180deg);
        }
      }

      ${StyledSubMenu} {
        padding: 0;
        display: flex;
      }
    }
  }
`

export const StyledPopularItemsToggle = styled.div`
  display: none;

  @media only screen and (min-width: 64em) {
    display: flex;
  }
`
