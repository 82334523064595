export function getProductOptions(product) {
  if (product?.hasOnlyDefaultVariant) {
    return []
  }

  if (!product?.options) {
    return null
  }

  return product.options.reduce((acc, { name, values, position }) => {
    if (!acc[name]) {
      acc[name] = {}
    }

    acc[name] = {
      values,
      position,
    }

    return acc
  }, {})
}
