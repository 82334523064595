import styled from "styled-components"

export const StyledModalInner = styled.div``

export const StyledModalCloseButton = styled.button`
  color: var(--color-irish-wolfhound);
  cursor: pointer;
  display: block;
  margin-left: auto;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  &:focus {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-great-dane) 0 0 0 4px;
  }
  &:hover {
    color: var(--color-great-dane);
  }
`

export const StyledModal = styled.aside`
  overflow-y: auto;
  scroll-padding-bottom: ${({ offsetTop }) => `${offsetTop}px`};
  bottom: 0;
  position: fixed;
  top: ${({ offsetTop }) => offsetTop}px;
  left: 50%;
  transform: translateX(-50%);
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
  background-color: #fff;
  padding: 20px;
  @media only screen and (min-width: 30em) {
    padding: 2em;
    margin: 1em;
  }
`

export const StyledModalBackdrop = styled.div`
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  transition: opacity 0.25s ease;
`
