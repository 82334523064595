import React from "react"
import {
  PURCHASE_TYPE_ONETIME,
  PURCHASE_TYPE_SUBSCRIPTION,
} from "../../constants"
import {
  formatPrice,
  getDiscountPercent,
  getDiscountPriceRaw,
} from "../../utils/money"
import { Select } from "../Form/Select"
import Icon from "../Icon/Icon"
import IconList from "../IconList/IconList"
import IconListItem from "../IconList/IconListItem"
import { Pill } from "../Pill/Pill"
import {
  StyledFormField,
  StyledFormFieldControl,
  StyledFormFieldGroup,
  StyledFormFieldInput,
  StyledFormFieldLabel,
  StyledFormFieldWrap,
  StyledMultiFormFieldWrap,
  StyledVariantPrice,
} from "./StyledFormFields"

function PurchaseOptions({
  purchaseType,
  handleChangePurchaseType,
  shippingInterval,
  handleChangeShippingInterval,
  subscriptionDiscount,
  onetimeVariant,
  subscriptionVariant,
}) {
  const discount = subscriptionDiscount
    ? getDiscountPercent(
        onetimeVariant.price,
        getDiscountPriceRaw(
          subscriptionVariant.price,
          subscriptionDiscount.value
        )
      )
    : getDiscountPercent(onetimeVariant.price, subscriptionVariant.price)

  const discountMessage = subscriptionDiscount
    ? `Save ${discount} Now and 10% on Repeat Deliveries`
    : `Save 10% on Repeat Deliveries`

  return (
    <StyledFormFieldGroup>
      <legend>Frequency</legend>
      <StyledFormFieldWrap>
        <StyledFormField isSelected={purchaseType === PURCHASE_TYPE_ONETIME}>
          <StyledFormFieldInput>
            <input
              type="radio"
              autoComplete="off"
              name="purchase_type"
              onChange={handleChangePurchaseType}
              value={PURCHASE_TYPE_ONETIME}
              checked={purchaseType === PURCHASE_TYPE_ONETIME}
            />
            <StyledFormFieldControl />
          </StyledFormFieldInput>
          <StyledFormFieldLabel>
            One-Time Purchase{" "}
            <StyledVariantPrice>
              {formatPrice(onetimeVariant.price)}
            </StyledVariantPrice>
          </StyledFormFieldLabel>
        </StyledFormField>
      </StyledFormFieldWrap>
      <StyledMultiFormFieldWrap
        isSelected={purchaseType === PURCHASE_TYPE_SUBSCRIPTION}
      >
        <StyledFormField>
          <StyledFormFieldInput>
            <input
              type="radio"
              autoComplete="off"
              name="purchase_type"
              value={PURCHASE_TYPE_SUBSCRIPTION}
              onChange={handleChangePurchaseType}
              checked={purchaseType === PURCHASE_TYPE_SUBSCRIPTION}
            />
            <StyledFormFieldControl />
          </StyledFormFieldInput>
          <StyledFormFieldLabel>
            <span>
              Subscribe & Save{" "}
              <Pill backgroundColor="var(--color-green)" color="#ffffff">
                {discount} off
              </Pill>
            </span>
            <StyledVariantPrice>
              {formatPrice(
                subscriptionDiscount
                  ? getDiscountPriceRaw(
                      subscriptionVariant.price,
                      subscriptionDiscount.value
                    )
                  : subscriptionVariant.price
              )}
            </StyledVariantPrice>
          </StyledFormFieldLabel>
        </StyledFormField>
        <IconList>
          <IconListItem>
            <Icon name="discount" />
            {discountMessage}
          </IconListItem>
          <IconListItem>
            <Icon name="truck" />
            Free Shipping
          </IconListItem>
          <IconListItem>
            <Icon name="published-with-changes" />
            Cancel or Change Anytime
          </IconListItem>
        </IconList>
        <Select
          orientation="horizontal"
          textColor="var(--color-wild-blue)"
          value={shippingInterval}
          onChange={handleChangeShippingInterval}
          label="Deliver"
          options={[
            { children: "every 2 weeks", value: "2 weeks" },
            { children: "every 3 weeks", value: "3 weeks" },
            { children: "every 4 weeks", value: "4 weeks" },
            { children: "every 5 weeks", value: "5 weeks" },
            { children: "every 6 weeks", value: "6 weeks" },
            { children: "every 7 weeks", value: "7 weeks" },
            { children: "every 8 weeks", value: "8 weeks" },
          ]}
        />
        <Pill backgroundColor="var(--color-green)" color="#ffffff">
          {discount} off
        </Pill>
      </StyledMultiFormFieldWrap>
    </StyledFormFieldGroup>
  )
}

export default PurchaseOptions
