const GID_REGEXP = /\/(\w[\w-]*)(?:\?(.*))*$/

export function parseGid(gid) {
  // prepends forward slash to help identify invalid id
  const id = `/${gid}`
  const matches = GID_REGEXP.exec(id)
  if (matches && matches[1] !== undefined) {
    return matches[1]
  }
  throw new Error(`Invalid gid: ${gid}`)
}
