import { em } from "polished"
import React from "react"
import styled from "styled-components"

const StyledColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(212px, 1fr));
  gap: ${em(24)};

  ${({ verticalAlignment }) =>
    verticalAlignment === "top"
      ? `align-items: start;`
      : verticalAlignment === "bottom"
      ? `align-items: end;`
      : verticalAlignment === "center" && `align-items: center;`}

  > img {
    object-fit: contain !important;
  }
`

function Columns({ children, verticalAlignment }) {
  return (
    <StyledColumns verticalAlignment={verticalAlignment}>
      {children}
    </StyledColumns>
  )
}

export default Columns
