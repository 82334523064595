import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { getMenuHierarchy } from "../utils/getMenuHierarchy"
import Newsletter from "./Newsletter/Newsletter"

const StyledFooterMenuWrap = styled.div`
  margin-top: 3rem;

  @media only screen and (min-width: 48em) {
    margin-top: 0rem;
  }
`

const StyledFooterMenus = styled.div`
  display: flex;
  gap: 2.5em;
  margin-left: auto;
`

const StyledFooterNav = styled.div`
  display: flex;
  gap: 2.5em;
  text-align: right;
  flex-wrap: wrap;
  flex: 1;
  max-width: 942px;

  @media only screen and (min-width: 48em) {
    border: 0;
  }
`

export const StyledFooterMenuTitle = styled.h2`
  color: #fff;
  font-size: 18px;
  margin-bottom: 1.25rem;
`

const StyledFooterMenu = styled.ul`
  color: #fff;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 0.625rem;
  }

  li > a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-bottom 250ms ease-in;
  }

  li > a:hover,
  li > a:focus {
    border-bottom: 1px solid var(--color-cameo);
  }
`

const StyledLink = styled.a``

function FooterNavigation() {
  const graphqlResult = useStaticQuery(graphql`
    query GET_ALL_FOOTER_MENUS($eq: WpMenuLocationEnum = GATSBY_FOOTER_MENU) {
      wpMenu(locations: { eq: $eq }) {
        menuItems {
          nodes {
            id
            label
            parentId
            url
            title
            target
            linkRelationship
          }
        }
      }
    }
  `)

  const menu = graphqlResult?.wpMenu
  const menuItems = menu?.menuItems?.nodes || []
  const menus = getMenuHierarchy(menuItems, { idKey: "id" })

  const isAbsolute = url => /^https?:\/\//i.test(url)

  return (
    <StyledFooterNav>
      <StyledFooterMenus>
        {menus.map(menu => (
          <StyledFooterMenuWrap key={menu.id}>
            <StyledFooterMenuTitle>{menu.label}</StyledFooterMenuTitle>
            <StyledFooterMenu>
              {menu.children.map(childMenuItem => {
                const linkProps = isAbsolute(childMenuItem.url)
                  ? {
                      href: childMenuItem.url,
                    }
                  : {
                      as: Link,
                      to: childMenuItem.url,
                    }
                return (
                  <li key={childMenuItem.id}>
                    <StyledLink
                      {...linkProps}
                      target={childMenuItem.target}
                      rel={childMenuItem.linkRelationship}
                    >
                      {childMenuItem.label}
                    </StyledLink>
                  </li>
                )
              })}
            </StyledFooterMenu>
          </StyledFooterMenuWrap>
        ))}
      </StyledFooterMenus>
      <Newsletter />
    </StyledFooterNav>
  )
}

export default FooterNavigation
