import { Link } from "gatsby"
import React from "react"
import PopularProducts from "./PopularProducts"
import {
  StyledAnchor,
  StyledPopularItemsToggle,
  StyledSubMenu,
  StyledSubMenuContainer,
  StyledSubMenuItem,
} from "./StyledNavigation"

function NavigationSubmenu({
  isAriaHidden,
  isAriaExpanded,
  cssClasses,
  hasPopularProductsMenu,
  popularProductsMenu,
  ...item
}) {
  const isAbsolute = url => /^https?:\/\//i.test(url)

  if (item.children.length) {
    return (
      <>
        <StyledSubMenu
          aria-hidden={isAriaHidden}
          aria-expanded={isAriaExpanded}
        >
          <StyledSubMenuContainer>
            {item.children.map(childMenuItem => {
              const linkProps = isAbsolute(childMenuItem.url)
                ? {
                    href: childMenuItem.url,
                  }
                : {
                    as: Link,
                    to: childMenuItem.url,
                  }

              return (
                <StyledSubMenuItem
                  key={childMenuItem.id}
                  className={childMenuItem.cssClasses}
                >
                  <StyledAnchor
                    {...linkProps}
                    target={childMenuItem.target}
                    rel={childMenuItem.linkRelationship}
                  >
                    {childMenuItem.label}
                  </StyledAnchor>
                </StyledSubMenuItem>
              )
            })}
          </StyledSubMenuContainer>
          {hasPopularProductsMenu && (
            <StyledPopularItemsToggle>
              <PopularProducts menu={popularProductsMenu} />
            </StyledPopularItemsToggle>
          )}
        </StyledSubMenu>
      </>
    )
  }

  return null
}

export default NavigationSubmenu
