export function getDiscountPriceRaw(original, discount) {
  return Math.abs(
    Number(original) - (Number(original) * Number(discount)) / 100
  )
}

export function getDiscountPercent(original, discounted) {
  return Math.abs(
    (Number(original) - Number(discounted)) / Number(original)
  ).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    style: "percent",
  })
}

export function formatPrice(price) {
  const style = "currency"
  const currency = price?.currencyCode || "USD"
  const value =
    typeof price === "string" || typeof price === "number"
      ? price
      : price?.amount

  if (value === "0.00") {
    return "FREE"
  }
  if (!value) {
    return "$0.00"
  }

  return Number(value).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style,
    currency,
  })
}
