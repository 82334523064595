import React from "react"

function ArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <clipPath id="a">
        <path d="M17.6 17.6h25.6v25.6H17.6z"></path>
      </clipPath>
      <circle cx="30" cy="30" r="30" fill="#fff" fillOpacity="0.7"></circle>
      <g clipPath="url(#a)">
        <path
          fill="#3d87cb"
          d="M38.932 29.333H25.95l5.962-5.962-1.514-1.504-8.534 8.533 8.534 8.533 1.504-1.504-5.952-5.962h12.98z"
        ></path>
      </g>
    </svg>
  )
}

export default ArrowLeft
