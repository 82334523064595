import { graphql, Link, useStaticQuery } from "gatsby"
import { em } from "polished"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useMediaQuery } from "../hooks/useMediaQuery"
import { useStore } from "../hooks/useStore"
import { getMenuHierarchy } from "../utils/getMenuHierarchy"
import { StyledBackdrop } from "./Drawer/StyledDrawer"
import Icon from "./Icon/Icon"
import Logo from "./Logo/Logo"
import NavigationMenuItem from "./Navigation/NavigationMenuItem"
import PopularProducts from "./Navigation/PopularProducts"
import {
  StyledAnchor,
  StyledMenuItem,
  StyledSubMenu,
  StyledSubMenuItem,
} from "./Navigation/StyledNavigation"

const NavBackdrop = styled(StyledBackdrop)`
  top: 73px;
  z-index: initial;

  @media only screen and (min-width: 64em) {
    display: none;
  }
`

const StyledMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  @media only screen and (min-width: 64em) {
    list-style: none;
    color: white;
    display: flex;
    align-items: center;

    gap: 3em;

    .lg-hidden {
      display: none;
    }
  }
`

const StyledPopularItemsToggle = styled.div`
  margin-top: 1.5em;
  margin-bottom: 7em;
  padding: 0 0 0 24px;
  @media only screen and (min-width: 64em) {
    display: none;
  }
`

const StyledNavToggle = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #fff;
  cursor: pointer;
  svg {
    display: block;
  }

  @media only screen and (min-width: 64em) {
    display: none;
  }
`

const StyledNavLeft = styled.nav`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  background-color: #ffffff;
  max-width: 475px;
  height: calc(100vh - 100%);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${StyledMenu} {
    padding: 0 24px;
  }

  ${StyledMenuItem} {
    ${StyledSubMenu}:first-child {
      display: none;
    }

    &:not(:first-child) {
      border-top: 1px solid var(--color-great-dane);
    }
  }

  ${StyledSubMenu} {
    border-top: 1px solid var(--color-great-dane);
  }

  @media only screen and (min-width: 64em) {
    background-color: var(--color-irish-wolfhound);
    position: static;
    display: block;
    height: initial;
    overflow: initial;

    ${StyledMenuItem} {
      &:not(:first-child) {
        border: 0;
      }
    }

    ${StyledMenu} {
      padding: 0;
    }

    ${StyledSubMenu} {
      border: 0;
    }
  }
`

const StyledNavRight = styled.nav`
  /* Maintain a condensed version of the right side menu */
  ${StyledMenuItem} {
    /* we know that the cart icon is always going to be last child, let's set the color otherwise it will inherit default StyledAnchor */
    &:last-child svg {
      display: block;
      color: #fff;
    }

    /* we don't want to display ALL menu items, just the Cart icon because the remaining will be injected into the mobile navigation menu */
    &:not(:last-child) {
      display: none;
    }
  }

  @media only screen and (min-width: 64em) {
    ${StyledMenuItem} {
      /* Style special HELP navigation */
      &:first-child {
        line-height: 1;
        border-radius: 6px;
        background-color: var(--color-staffie);
        position: relative;

        &:hover,
        &:focus-within {
          border-radius: 6px 6px 0 0;
        }

        > button {
          padding: ${em(16)} ${em(24)};
        }

        > ${StyledSubMenu} {
          left: initial;
          right: 0;
        }

        ${StyledSubMenuItem} {
          &:first-child {
            background-color: var(--color-staffie);
            ${StyledAnchor} {
              text-align: center;
              color: #ffffff;
              border: 0;
              &:before {
                content: none;
              }
            }
          }

          > ${StyledAnchor} {
            line-height: 80px;
          }
        }
      }
    }

    ${StyledMenuItem} {
      &:not(:last-child) {
        display: block;
      }

      &:last-child {
        svg {
          transform: none;
        }
      }
    }
  }
`

const CartBubble = styled.span`
  height: 20px;
  width: 20px;
  background-color: var(--color-italian-greyhound);
  color: var(--color-great-dane);
  border-radius: 50%;
  line-height: 1;
  display: grid;
  place-items: center;
  top: -10px;
  position: absolute;
  text-align: center;
  right: -10px;
`

function PrimaryNavigation() {
  const graphqlResult = useStaticQuery(graphql`
    query GET_ALL_PRIMARY_MENUS($in: [WpMenuLocationEnum] = [PRIMARY, FOOTER]) {
      allWpMenu(filter: { locations: { in: $in } }) {
        edges {
          node {
            name
            menuItems {
              nodes {
                id
                parentId
                url
                title
                target
                label
                linkRelationship
                cssClasses
              }
            }
            popularProductsMenu {
              popularItems {
                popularShopifyProduct
                title
                subtext
                popularShopifyProductPage {
                  ... on WpPage {
                    id
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const navRef = useRef()
  const navToggleRef = useRef()
  const menuLeftRef = useRef()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const { checkout } = useStore()
  const isDesktop = useMediaQuery("(min-width: 1024px)")

  useEffect(() => {
    if (isNavOpen) {
      navRef.current.tabIndex = "-1"
      navRef.current.focus()
    }
  }, [isNavOpen, navRef])

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        navRef.current &&
        !navRef.current.contains(e.target) &&
        !navToggleRef.current.contains(e.target)
      ) {
        setIsNavOpen(false)
      }
    }

    if (!isDesktop && navRef.current) {
      document.addEventListener("click", handleClickOutside)
    }

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  })

  const menus = graphqlResult?.allWpMenu?.edges
  const [navigationLeft] = menus
    .filter(({ node }) => node.name === "Navigation Left")
    .map(m => m.node)

  const [navigationRight] = menus
    .filter(({ node }) => node.name === "Navigation Right")
    .map(m => m.node)

  const navigationLeftItems = navigationLeft?.menuItems?.nodes || []
  const navigationLeftPopularProducts =
    navigationLeft?.popularProductsMenu?.popularItems || []

  const navigationRightItems = navigationRight?.menuItems?.nodes || []
  const navigationRightPopularProducts =
    navigationRight?.popularProductsMenu?.popularItems || []

  const menuLeft = {
    items: getMenuHierarchy(navigationLeftItems, { idKey: "id" }),
    popularProducts: navigationLeftPopularProducts,
  }

  const menuRight = {
    items: getMenuHierarchy(navigationRightItems, { idKey: "id" }),
    popularProducts: navigationRightPopularProducts,
  }

  const lineItemCount = checkout.lineItems.reduce(
    (acc, curr) => acc + curr.quantity,
    0
  )

  return (
    <>
      {isNavOpen && <NavBackdrop />}
      <StyledNavToggle
        onClick={() => setIsNavOpen(!isNavOpen)}
        aria-label="Toggle navigation visibility"
        aria-controls="navigation"
        ref={navToggleRef}
      >
        {isNavOpen ? (
          <Icon name="close" width={28} height={28} color="#ffffff" />
        ) : (
          <Icon name="hamburger" width={28} height={28} fill="#ffffff" />
        )}
      </StyledNavToggle>
      <StyledNavLeft
        aria-label="Primary navigation"
        style={{
          ...(isNavOpen && { display: "block" }),
        }}
        ref={navRef}
        aria-expanded={isNavOpen ? "true" : "false"}
        id="navigation"
      >
        <StyledMenu ref={menuLeftRef}>
          {menuLeft.items.map(menuItem => (
            <NavigationMenuItem
              key={menuItem.id}
              item={menuItem}
              hasPopularProductsMenu={!!menuLeft.popularProducts.length}
              popularProductsMenu={menuLeft.popularProducts}
              isDesktop={isDesktop}
            />
          ))}
        </StyledMenu>
        <StyledPopularItemsToggle>
          <PopularProducts menu={navigationLeftPopularProducts} />
        </StyledPopularItemsToggle>
      </StyledNavLeft>
      <Logo />
      <StyledNavRight aria-label="Primary navigation right">
        <StyledMenu>
          {menuRight.items.map(menuItem => (
            <NavigationMenuItem
              key={menuItem.id}
              item={menuItem}
              hasPopularProductsMenu={!!menuRight.popularProducts.length}
              popularProductsMenu={menuRight.popularProducts}
              isDesktop={isDesktop}
            />
          ))}
          <StyledMenuItem>
            <StyledAnchor as={Link} to="/cart" aria-label="Cart page">
              <Icon name="cart" />
              {checkout.lineItems.length > 0 ? (
                <CartBubble>{lineItemCount}</CartBubble>
              ) : null}
            </StyledAnchor>
          </StyledMenuItem>
        </StyledMenu>
      </StyledNavRight>
    </>
  )
}

export default PrimaryNavigation
