import React from "react"
import styled from "styled-components"

const StyledBowl = styled.section`
  margin: 0 auto;
  max-width: 1248px;
  padding: 0 24px;
  width: 100%;

  .wp-block-wildearth-bowl-image .inline-gatsby-image-wrapper {
    display: block;
    margin: 0 auto;
  }

  .wp-block-wildearth-bowl-content > :where(h1, h2, h3, h4, h5, h6) + p {
    margin-top: 8px;
  }

  .wp-block-wildearth-bowl-content:not(:last-child) {
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 48em) {
    display: grid;
    column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "image image"
      "topLeft topRight"
      "bottomLeft bottomRight";

    padding: 0 40px;
    row-gap: 40px;

    .wp-block-wildearth-bowl-image {
      /* to horizontally align inner gatsby image */
      display: grid;
      place-content: center;
      grid-area: image;
      place-self: center;
      width: 100%;
    }

    .wp-block-wildearth-bowl-content:not(:last-child) {
      margin-bottom: 0;
    }

    .wp-block-wildearth-bowl-content {
      margin-bottom: 0;
      width: 100%;
    }

    .wp-block-wildearth-bowl-content:nth-of-type(2) {
      grid-area: topLeft;
    }

    .wp-block-wildearth-bowl-content:nth-of-type(3) {
      grid-area: topRight;
    }

    .wp-block-wildearth-bowl-content:nth-of-type(4) {
      grid-area: bottomLeft;
    }

    .wp-block-wildearth-bowl-content:nth-of-type(5) {
      grid-area: bottomRight;
    }
  }

  @media only screen and (min-width: 64em) {
    column-gap: 64px;
    grid-template-columns: minmax(auto, 282px) minmax(350px, 550px) minmax(
        auto,
        282px
      );
    grid-template-areas:
      "topLeft image topRight"
      "bottomLeft image bottomRight";

    row-gap: 72px;
  }
`

function Bowl({ children }) {
  return <StyledBowl>{children}</StyledBowl>
}

export default Bowl
