import React from "react"

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <clipPath id="a">
        <path d="M0 0h25.6v25.6H0z" transform="rotate(180 21.2 21.2)"></path>
      </clipPath>
      <circle
        cx="30"
        cy="30"
        r="30"
        fill="#fff"
        fillOpacity="0.7"
        transform="rotate(180 30 30)"
      ></circle>
      <g clipPath="url(#a)">
        <path
          fill="#3d87cb"
          d="M21.068 30.667H34.05l-5.962 5.962 1.514 1.504 8.534-8.533-8.534-8.533-1.504 1.504 5.952 5.962H21.07z"
        ></path>
      </g>
    </svg>
  )
}

export default ArrowRight
