import React, { useState } from "react"
import styled from "styled-components"
import { StyledButton } from "../Buttons/StyledButton"
import { StyledFooterMenuTitle } from "../FooterNavigation"
import { StyledEmailInput } from "../Form/Email"
import Spinner from "../Spinner/Spinner"

const StyledSubmit = styled(StyledButton)`
  background-color: var(--color-staffie);
  border-color: var(--color-staffie);
  border-radius: 8px;
  flex-shrink: 0;
  margin-left: 12px;
  display: inline-flex;
  align-items: center;
  padding: 1em 1.125em;

  > svg {
    margin-right: 0.5em;
  }

  &:focus {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-staffie) 0 0 0 4px;
  }
  &:hover {
    background-color: var(--color-staffie);
  }
`

const StyledForm = styled.form`
  fieldset {
    display: flex;
    align-items: flex-start;
    border: 0;
    margin: 0;
    padding: 0;

    > div {
      width: 100%;

      p {
        text-align: left;
        color: #fff;
      }
    }

    input[type="email"] {
      width: 100%;
      border: 2px solid #fff;
    }
  }
`

const StyledNewsletter = styled.div`
  margin-left: auto;
  flex-basis: 415px;

  h2 {
    text-align: left;
    color: #fff;
    font-size: 18px;
    margin-bottom: 1.25rem;
  }
`

function Newsletter() {
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [done, setDone] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      setLoading(true)

      if (!email) {
        setLoading(false)
        return
      }

      const url = `${process.env.GATSBY_APP_STOREFRONT_GATEWAY_API}/lists/L9Hwqv?key=${process.env.GATSBY_APP_STOREFRONT_GATEWAY_API_TOKEN}`
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          profiles: [{ email }],
        }),
      }

      const response = await fetch(url, options)
      const result = await response.json()

      if (result) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "dl_subscribe",
        })

        setLoading(false)
        setDone(true)
      }
    } catch (e) {
      setLoading(false)
      setError("Oops! Something went wrong.")
      console.error(e)
    }
  }

  const getButtonText = () => {
    if (loading) {
      return (
        <>
          <Spinner height={16} width={16} color="#ffffff" /> Loading
        </>
      )
    }

    if (!loading && done) {
      return "✔︎ Subscribed"
    }

    return "Sign Up"
  }

  return (
    <StyledNewsletter>
      <StyledFooterMenuTitle>Join our mailing list</StyledFooterMenuTitle>
      <StyledForm action="" method="POST" onSubmit={handleSubmit}>
        <fieldset disabled={loading}>
          <div>
            <StyledEmailInput
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email address"
              required
            />
            {error && <p>{error}</p>}
          </div>
          <StyledSubmit>{getButtonText()}</StyledSubmit>
        </fieldset>
      </StyledForm>
    </StyledNewsletter>
  )
}

export default Newsletter
