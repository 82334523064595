import parse from "html-react-parser"
import React from "react"
import styled from "styled-components"

const StyledKicker = styled.p`
  font-weight: var(--font-bold);
  font-size: 1em;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: var(--color-irish-wolfhound);
  margin: 20px 0;

  ${({ align }) => align === "center" && `text-align: center;`}
  ${({ align }) => align === "right" && `text-align: right;`}

  @media only screen and (min-width: 48em) {
    margin: 0.5em 0px;
  }
`

function Kicker({ heading, align }) {
  return <StyledKicker align={align}>{parse(heading)}</StyledKicker>
}

export default Kicker
