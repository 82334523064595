import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { useImageByDatabaseId } from "../../hooks/useImageByDatabaseId"

function getMediaPosition({ x = "0.5", y = "0.5" } = {}) {
  return `${Math.round(x * 100)}% ${Math.round(y * 100)}%`
}

// group together common styles for positioning our pseudo element that serves as our background image overlay
const setOverlay = () => `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  content: "";
`

const StyledMobileBackground = styled(GatsbyImage)`
  @media only screen and (min-width: 64em) {
    display: none;
  }
`

const StyledDesktopBackground = styled(GatsbyImage)`
  @media only screen and (max-width: 1023px) {
    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
  }

  @media only screen and (min-width: 64em) {
    grid-area: 1/1;
    ${({ dimRatio }) => dimRatio && `opacity: ${dimRatio * 0.01}`};
  }
`

const StyledQuadInner = styled.div`
  padding: 40px 20px;

  .wp-block-wildearth-quad-header {
    margin-bottom: 40px;
  }

  .wp-block-wildearth-quad-item {
    display: flex;
    &:not(:last-of-type) {
      margin-bottom: 40px;
    }

    .wp-block-wildearth-quad-item-content {
      margin: 0;
    }

    > figure.wp-block-image {
      flex-basis: 102px;
      flex-shrink: 0;
      margin: 0 24px 0 0;
      .inline-gatsby-image-wrapper {
        width: 102px !important;
        img {
          object-fit: contain !important;
        }
      }
    }

    .wp-block-wildearth-quad-item-content {
      max-width: 384px;
      flex-grow: 1;

      > :where(h1, h2, h3, h4, h5, h6) {
        margin-bottom: 8px;

        + * {
          margin-top: 8px;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    display: grid;
    column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
    grid-template-areas:
      "header header"
      "topLeft topRight"
      "bottomLeft bottomRight";
    margin: 0;
    max-width: none;
    padding: 68px 40px;
    row-gap: 40px;

    .wp-block-wildearth-quad-header {
      justify-self: center;
      grid-column: 1 / span 2;
      margin-bottom: 0;
      max-width: 782px;
      width: 100%;
    }

    .wp-block-wildearth-quad-item {
      margin-bottom: 0;
    }

    .wp-block-wildearth-quad-header
      ~ .wp-block-wildearth-quad-item:nth-of-type(even) {
      grid-column: 1;
      grid-row: auto;
    }

    .wp-block-wildearth-quad-header
      ~ .wp-block-wildearth-quad-item:nth-of-type(odd) {
      grid-column: 2;
      grid-row: auto;
    }
  }

  @media only screen and (min-width: 64em) {
    padding: 80px 40px;
    margin-left: auto;
    width: 100%;
    z-index: 1;
    grid-area: 1/1;

    .wp-block-wildearth-quad-header {
      margin-bottom: 40px;
    }

    .wp-block-wildearth-quad-header
      ~ .wp-block-wildearth-quad-item:nth-of-type(n),
    .wp-block-wildearth-quad-item:nth-of-type(n) {
      grid-area: initial;
      grid-column: 2 / auto;
      justify-self: flex-end;
      margin-bottom: 0;
    }
  }
`

const StyledQuad = styled.section`
  background-color: ${({ backgroundColor, mobileBackgroundColor }) =>
    !mobileBackgroundColor && backgroundColor
      ? backgroundColor
      : mobileBackgroundColor};

  @media only screen and (min-width: 64em) {
    ${({ backgroundColor }) =>
      backgroundColor && `background-color: ${backgroundColor};`}

    display: grid;
  }
`

function Quad({
  children,
  dimRatio,
  backgroundColor,
  customGradient,
  mediaId,
  mediaPosition,

  mobileBackgroundColor,
  mobileCustomGradient,
  mobileMediaPosition,
  mobileMediaId,
}) {
  const imageByDatabaseId = useImageByDatabaseId(mediaId)
  const image = getImage(imageByDatabaseId)

  const mobileImageByDatabaseId = useImageByDatabaseId(mobileMediaId)
  const mobileImage = getImage(mobileImageByDatabaseId)

  return (
    <StyledQuad
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      hasMobileMedia={!!mobileImage}
    >
      {mobileImage && (
        <StyledMobileBackground
          image={mobileImage}
          alt=""
          objectPosition={mobileMediaPosition}
        />
      )}
      {dimRatio !== 100 && image && (
        <StyledDesktopBackground
          image={image}
          alt=""
          objectPosition={mediaPosition}
          dimRatio={dimRatio}
          hideOnMobile={!!mobileImage}
        />
      )}
      <StyledQuadInner>{children}</StyledQuadInner>
    </StyledQuad>
  )
}

export default Quad
