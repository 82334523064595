import React, { useRef } from "react"
import { useScript } from "../../hooks/useScript"
import { useVisibility } from "../../hooks/useVisibility"

function WidgetWistia({ embedId }) {
  const node = useRef()
  const isVisible = useVisibility(node, 150)

  useScript("https://fast.wistia.com/assets/external/E-v1.js", isVisible, true)
  useScript(
    `https://fast.wistia.com/embed/medias/${embedId}.jsonp`,
    isVisible,
    true
  )

  return (
    <div
      className="wistia_responsive_padding"
      style={{ padding: "56.25% 0 0 0", position: "relative" }}
      ref={node}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <div
          className={`wistia_embed wistia_async_${embedId} seo=false videoFoam=true`}
          style={{ height: "100%", position: "relative", width: "100%" }}
        />
      </div>
    </div>
  )
}

export default WidgetWistia
