import React from "react"
import styled from "styled-components"

const StyledContentBlock = styled.section`
  background-color: ${({ backgroundColor }) => backgroundColor};
`

function isChildrenNull(children) {
  return React.Children.count(children)
}

function ContentBlock({ variation, children, ...rest }) {
  switch (variation) {
    case "content-block":
      return <StyledContentBlock {...rest}>{children}</StyledContentBlock>

    default:
      return isChildrenNull(children) ? children : null
  }
}

export default ContentBlock
