import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { useImageByDatabaseId } from "../../hooks/useImageByDatabaseId"

// group together common styles for positioning our pseudo element that serves as our background image overlay
const setOverlay = () => `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  content: "";
`

// group together positioning rules for our hero content
const setMediaPositionLeft = () => `
  > div:only-child {
    grid-column: 2;
    grid-row: 1;
  }

  > div:first-child:nth-last-child(2) {
    grid-column: 2;
    grid-row: 1;
  }
`

const StyledHero = styled.section`
  position: relative;

  &:before {
    ${({ overlayColor }) =>
      overlayColor && `background-color: ${overlayColor};`}
    ${({ customGradient }) =>
      customGradient && `background-image: ${customGradient};`}
    ${({ hasBackgroundDim, overlayColor, customGradient }) =>
      hasBackgroundDim && (overlayColor || customGradient) && setOverlay()}

    opacity: ${({ dimRatio }) => dimRatio && dimRatio * 0.01};
  }
`

const StyledHeroBackground = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  -o-object-fit: cover;
  object-fit: cover;
`

const StyledHeroInner = styled.div`
  position: relative;
  z-index: 1;
  padding: 3rem 1rem;
  min-height: 600px;

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, calc(50% - 0.5rem)));
    align-items: center;
    gap: 1rem;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;

    ${({ mediaPosition }) => mediaPosition === "left" && setMediaPositionLeft()}
  }
`

/**
 * Styled component used for rendering a parsed Gutenberg Hero component
 *
 * @param {Object} props stringified object of component props provided by WordPress Gutenberg
 * @param {string} props.customGradient linear-gradient property used as the value for `background-image`
 * @param {number} props.dimRatio opacity level of the background image overlay on a scale of 0-100
 * @param {number} props.mediaId database ID of the background image if present
 * @param {string} props.mediaPosition identifier for placing the inner right HeroContent on a specific side
 * @param {string} props.overlayColor color code of background image overlay
 *
 * @returns {Element}
 */
function Hero({
  children,
  customGradient,
  dimRatio,
  mediaId,
  mediaPosition,
  overlayColor,
}) {
  const imageByDatabaseId = useImageByDatabaseId(mediaId)
  const image = getImage(imageByDatabaseId)

  return (
    <StyledHero
      hasBackgroundDim={dimRatio}
      customGradient={customGradient}
      overlayColor={overlayColor}
      dimRatio={dimRatio}
    >
      {dimRatio !== 100 && image && (
        <StyledHeroBackground
          image={image}
          style={{ position: "absolute" }}
          alt=""
        />
      )}
      <StyledHeroInner mediaPosition={mediaPosition}>
        {children}
      </StyledHeroInner>
    </StyledHero>
  )
}

export default Hero
