import { em } from "polished"
import styled from "styled-components"
import { StyledSelect } from "../Form/Select"
import Icon from "../Icon/Icon"
import { StyledList } from "../IconList/IconList"
import { StyledPill } from "../Pill/Pill"

export const RadioInputIcon = styled(Icon).attrs({
  name: "radio",
})``

export const RadioSelectedBackground = styled.span`
  position: absolute;
  left: 0;
  background-color: var(--color-wild-blue);
  width: 50%;
  height: 100%;
  top: 0;
  transition: ease 0.5s left;
  border-radius: 4px;

  ${({ isDefaultSelection }) => !isDefaultSelection && `left: 50%;`};
`

export const StyledFormFieldWrap = styled.div`
  &:focus-within {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-great-dane) 0 0 0 4px;
    border-radius: 12px;
  }
`

export const StyledVariantPrice = styled.span`
  font-family: var(--font-body);
  font-size: 1.5em;
  font-weight: var(--font-bold);
  display: block;
`

export const StyledPillFormFieldGroup = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;

  legend {
    color: var(--color-staffie);
    font-weight: var(--font-bold);
    margin-bottom: ${em(10)};
    padding: 0;
  }

  margin-bottom: 32px;
`

export const StyledFormFieldGroup = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;

  legend {
    color: var(--color-staffie);
    font-weight: var(--font-bold);
    margin-bottom: ${em(10)};
    padding: 0;
  }

  margin-bottom: ${em(24)};
`

export const StyledFormFieldControl = styled.span`
  display: grid;
  place-items: center;
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  border: 2px solid var(--color-gray);
  transform: translateY(-0.05em);
`

export const StyledFormFieldLabel = styled.span`
  font-size: 1em;
  font-weight: var(--font-bold);
  line-height: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > span:first-child {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${em(8)};
  }
`

export const StyledFormField = styled.label`
  display: grid;
  cursor: pointer;
  position: relative;
  width: 100%;

  border-radius: 12px;
  color: var(--color-great-dane);
  border: 2px solid var(--color-gray);

  grid-template-columns: min-content auto;
  align-items: flex-start;
  grid-gap: ${em(18)};
  padding: 1em 0.75em;

  ${({ isSelected }) =>
    isSelected && `border: 2px solid var(--color-irish-wolfhound); `}

  &:not(:last-of-type) {
    margin-bottom: ${em(10)};
  }
`

export const StyledMultiFormFieldWrap = styled(StyledFormFieldWrap)`
  border-radius: 12px;
  color: var(--color-great-dane);
  border: 2px solid var(--color-gray);
  margin-top: ${em(10)};
  position: relative;

  ${({ isSelected }) =>
    isSelected && `border: 2px solid var(--color-irish-wolfhound); `}

  > ${StyledPill} {
    display: ${({ isSelected }) => isSelected && "none"};
    position: absolute;
    right: 50%;
    transform: translate3d(50%, -50%, 0);
    line-height: 1;
  }

  ${StyledFormField} {
    border-radius: 0;
    border: 0;
    margin: 0;

    ${StyledPill} {
      display: ${({ isSelected }) => !isSelected && "none"};
    }
  }

  ${StyledSelect} {
    display: none;
    padding: 1em 0.75em;
    border-top: 2px solid var(--color-irish-wolfhound);
    margin: 0;
    width: 100%;

    > div {
      flex-wrap: nowrap;
    }

    span,
    select {
      color: var(--color-irish-wolfhound);
    }

    select {
      border-bottom: var(--color-irish-wolfhound);
      background-image: url("data:image/svg+xml,%3Csvg fill='none' height='30' viewBox='0 0 30 30' width='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m15.0012 7.2875 3.9625 3.9625 1.7625-1.7625-5.725-5.7375-5.73753 5.7375 1.77503 1.7625zm0 15.425-3.9625-3.9625-1.76253 1.7625 5.72503 5.7375 5.7375-5.7375-1.775-1.7625z' fill='%23004986'/%3E%3C/svg%3E");
      width: 100%;
      margin-right: 0;
      padding: 0;
      background-repeat: no-repeat;
    }
  }

  ${({ isSelected }) =>
    isSelected && `${StyledSelect} {display: inline-block;}`}

  ${StyledList} {
    padding: 0 ${em(12)} ${em(12)} ${em(12)};
    display: ${({ isSelected }) => (isSelected ? `block` : `none`)};

    li {
      align-items: center;
    }
    svg {
      color: var(--color-green);
    }
  }

  @media only screen and (min-width: 48em) {
    ${StyledList} {
      padding-left: calc(1.125em + ${em(24)});
    }

    ${StyledSelect} {
      padding-left: calc(1.125em + ${em(24)});
    }
  }
`

export const StyledFormFieldInput = styled.span`
  display: flex;
  align-items: center;
  align-self: center;

  input {
    height: 0;
    opacity: 0;
    width: 0;
  }

  input + ${StyledFormFieldControl}:before {
    content: "";
    width: 0.625em;
    height: 0.625em;
    border-radius: 50%;
    background-color: #ffffff;
  }

  input:checked + ${StyledFormFieldControl}:before {
    background-color: var(--color-irish-wolfhound);
  }
`

export const StyledFormFieldPillLabel = styled(StyledFormFieldLabel)`
  display: inline-block;
`

export const StyledFormFieldPillGroup = styled(StyledFormFieldGroup)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
`

export const StyledFormFieldPill = styled.label`
  font-weight: var(--font-bold);
  padding: 1em;
  cursor: pointer;
  background-color: #fff;
  border-radius: 2em;
  border: 2px solid var(--color-great-dane);
  display: inline-block;
  font-size: 14px;

  @media only screen and (min-width: 48em) {
    font-size: 16px;
  }

  ${({ isSelected }) =>
    isSelected &&
    `background-color: var(--color-irish-wolfhound);border-color: var(--color-irish-wolfhound);`}
  ${({ isSelected }) => isSelected && `${StyledFormFieldLabel} {color: #fff;}`}

  &:focus-within {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-great-dane) 0 0 0 4px;
  }
`

export const StyledFormFieldPillInput = styled.input`
  height: 0;
  opacity: 0;
  width: 0;
`

export const StyledDropdownWrap = styled.label`
  width: 100%;
  border: 2px solid var(--color-irish-wolfhound);
  border-radius: ${em(12)};
  padding: 1em 0.75em;
  font-size: 1rem;
  cursor: pointer;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: inline-block;
  &:focus-within {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-great-dane) 0 0 0 4px;
    border-radius: 12px;
  }
`

export const StyledDropdownSelect = styled.select`
  appearance: none;
  background-color: transparent;
  text-overflow: ellipsis;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  font-weight: var(--font-bold);

  background-image: url("data:image/svg+xml,%3Csvg fill='none' height='30' viewBox='0 0 30 30' width='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m15.0012 7.2875 3.9625 3.9625 1.7625-1.7625-5.725-5.7375-5.73753 5.7375 1.77503 1.7625zm0 15.425-3.9625-3.9625-1.76253 1.7625 5.72503 5.7375 5.7375-5.7375-1.775-1.7625z' fill='%23004986'/%3E%3C/svg%3E");
  width: 100%;
  margin-right: 0;
  padding: 0 25px 0 0;
  background-repeat: no-repeat;
  background-position: right;
  outline: none;
`
