import { em } from "polished"
import React from "react"
import styled from "styled-components"
import { columns } from "../../utils/columns"

const StyledCardDecksInner = styled.div`
  > :first-child:where(h1, h2, h3, h4, h5, h6) {
    text-align: ${({ alignment }) => alignment || "left"};
    color: ${({ textColor }) => textColor};
  }

  > :first-child + div {
    margin-top: 48px;
  }
`

const StyledCardDecks = styled.section`
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};

  ${StyledCardDecksInner} {
    max-width: 1250px;
    padding: ${em(20)};
    margin: 0 auto;
    width: 100%;
  }

  ${({ mobileDisableAutoWrap }) =>
    mobileDisableAutoWrap &&
    `${StyledCardDecksInner} > div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  `}

  &.has-cards-2 {
    ${StyledCardDecksInner} > div {
      gap: 40px;
      > article {
        flex: 1 1 50%;
      }
    }
  }

  &.has-cards-3 {
    ${StyledCardDecksInner} > div {
      flex-wrap: wrap;
      gap: 3em;
      > article {
        max-width: ${columns(12 / 3)}px;
        flex: 1 0 25%;
      }
    }
  }

  @media only screen and (min-width: 480px) {
    ${StyledCardDecksInner} > div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    &.has-cards-2 {
      ${StyledCardDecksInner} > div {
        gap: 72px;
      }
    }
  }
`

function CardDecks({
  children,
  backgroundColor,
  count,
  alignment,
  mobileDisableAutoWrap,
}) {
  return (
    <StyledCardDecks
      count={count}
      backgroundColor={backgroundColor}
      className={`has-cards-${count}`}
      mobileDisableAutoWrap={mobileDisableAutoWrap}
    >
      <StyledCardDecksInner alignment={alignment}>
        {children}
      </StyledCardDecksInner>
    </StyledCardDecks>
  )
}

export default CardDecks
