import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const StyledGalleryImage = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
`

function GalleryImage({ handleClick, image, altText }) {
  return handleClick ? (
    <StyledGalleryImage onClick={handleClick}>
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        alt={altText}
      />
    </StyledGalleryImage>
  ) : (
    <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={altText} />
  )
}

export default GalleryImage
