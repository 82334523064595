const COLUMN_CONFIG = {
  desktop: {
    column: "94px",
    gutter: "24px",
  },
  tablet: {
    column: "154px",
    gutter: "24px",
  },
}

export function columns(count, viewport = "desktop") {
  const column = parseInt(COLUMN_CONFIG[viewport]["column"], 10)
  const gutter = parseInt(COLUMN_CONFIG[viewport]["gutter"], 10)

  return count * column + (count - 1) * gutter
}
