import React from "react"
import styled from "styled-components"
import { columns } from "../../utils/columns"

const StyledContentSplit = styled.div`
  position: relative;
  @media only screen and (min-width: 48em) {
    padding: 40px 20px;
  }
`

const StyledContentSplitInner = styled.div`
  display: flex;
  flex-direction: ${({ contentPosition }) =>
    contentPosition === "left" ? "column-reverse" : "column"};

  max-width: ${() => columns(10)}px;
  width: 100%;
  margin: 0 auto;

  > div:first-child {
    ${({ contentPosition }) =>
      contentPosition === "left" && "padding: 48px 24px"};
  }

  > div:nth-child(2) {
    ${({ contentPosition }) =>
      contentPosition === "right" && "padding: 48px 24px"};
  }

  @media only screen and (min-width: 768px) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
    gap: 3em;

    > div:first-child {
      ${({ contentPosition }) =>
        contentPosition === "left" &&
        `flex-grow: 1; flex-shrink: 1; max-width: ${
          columns(4) + "px"
        }; padding: 0;`};
      ${({ contentPosition }) =>
        contentPosition === "right" &&
        `flex-grow: 1; max-width: ${(columns(5) / columns(10)) * 100 + "%"};`};
    }

    > div:nth-child(2) {
      ${({ contentPosition }) =>
        contentPosition === "left" &&
        `flex-grow: 1; max-width: ${(columns(5) / columns(10)) * 100 + "%"};`};
      ${({ contentPosition }) =>
        contentPosition === "right" &&
        `flex-grow: 1; flex-shrink: 1; max-width: ${
          columns(4) + "px"
        }; padding: 0;`};
    }
  }
`

/**
 * Styled component used for rendering a parsed Gutenberg ContentFeature component
 *
 * @param {Object} props stringified object of component props provided by WordPress Gutenberg
 *
 * @returns {Element}
 */
function ContentSplit({ children, contentPosition }) {
  return (
    <StyledContentSplit>
      <StyledContentSplitInner contentPosition={contentPosition}>
        {children}
      </StyledContentSplitInner>
    </StyledContentSplit>
  )
}

export default ContentSplit
