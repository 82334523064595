/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import * as React from "react"
import { Helmet } from "react-helmet"
import { isUrl } from "../utils/isUrl"

function SEO({
  description,
  canonical,
  lang,
  meta,
  title,
  twitterTitle,
  twitterDescription,
  twitterImage,
  opengraphTitle,
  opengraphDescription,
  opengraphType,
  opengraphImage,
  metaRobotsNofollow,
  metaRobotsNoindex,
  schema,
}) {
  const { origin, pathname } = useLocation()

  const twDescription = twitterDescription.length
    ? twitterDescription
    : description

  const ogDescription = opengraphDescription.length
    ? opengraphDescription
    : description

  const normalizedPageTitle = (title || "").replace("- Wild Earth", "").trim()

  const twTitle = twitterTitle.length ? twitterTitle : normalizedPageTitle
  const ogTitle = opengraphTitle.length ? opengraphTitle : normalizedPageTitle

  const ogType = opengraphType || `website`
  const hasRobotsMeta =
    metaRobotsNofollow !== "follow" || metaRobotsNoindex !== "index"

  const updateSchema = data => {
    const { "@graph": graph = [], ...rest } = JSON.parse(data || {})
    const setObjectId = item => {
      const { "@id": itemUrl } = item

      if (isUrl(itemUrl)) {
        return {
          ...item,
          "@id": itemUrl,
        }
      }

      return {
        ...item,
        "@id": origin + itemUrl,
      }
    }

    const updatedGraph = graph.map(setObjectId)
    return JSON.stringify({ ...rest, "@graph": updatedGraph })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      
      meta={[
        ...(description?.length
          ? [
              {
                name: `description`,
                content: description,
              },
            ]
          : []),
        ...(ogTitle?.length
          ? [
              {
                property: `og:title`,
                content: ogTitle,
              },
            ]
          : []),
        ...(ogDescription?.length
          ? [
              {
                property: `og:description`,
                content: ogDescription,
              },
            ]
          : []),
        {
          property: `og:type`,
          content: ogType,
        },
        ...(opengraphImage?.sourceUrl?.length
          ? [
              {
                property: `og:image`,
                content: opengraphImage?.sourceUrl.replace(
                  /https?:\/\//gim,
                  "http://"
                ),
              },
              {
                property: `og:image:secure_url`,
                content: opengraphImage?.sourceUrl.replace(
                  /https?:\/\//gim,
                  "https://"
                ),
              },
            ]
          : []),
        ...(twTitle?.length
          ? [
              {
                name: `twitter:title`,
                content: twTitle,
              },
            ]
          : []),
        ...(twDescription?.length
          ? [
              {
                name: `twitter:description`,
                content: twDescription,
              },
            ]
          : []),
        ...(twitterImage?.sourceUrl?.length
          ? [
              {
                property: `twitter:image`,
                content: twitterImage.sourceUrl,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
            ]
          : []),
      ].concat(meta)}
    >
      {schema?.raw && (
        <script type="application/ld+json">{updateSchema(schema.raw)}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  schema: {
    raw: ``,
  },
  metaRobotsNofollow: "follow",
  metaRobotsNoindex: "index",
  canonical: ``,
  twitterTitle: ``,
  twitterDescription: ``,
  twitterImage: {
    sourceUrl: ``,
  },
  opengraphTitle: ``,
  opengraphDescription: ``,
  opengraphImage: {
    sourceUrl: ``,
  },
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    raw: PropTypes.string,
  }),
  metaRobotsNofollow: PropTypes.string,
  metaRobotsNoindex: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.shape({
    sourceUrl: PropTypes.string,
  }),
  opengraphTitle: PropTypes.string,
  opengraphDescription: PropTypes.string,
  opengraphImage: PropTypes.shape({
    sourceUrl: PropTypes.string,
  }),
  canonical: PropTypes.string,
}

export default SEO
