import React from "react"
import styled from "styled-components"

function getMediaPosition({ x, y }) {
  return `${Math.round(x * 100)}% ${Math.round(y * 100)}%`
}

const ALIGN_SELF_PROPERTIES = { top: "start", center: "center", bottom: "end" }

export const StyledHeroSplitImage = styled.div`
  position: relative;
  display: block !important;

  .inline-gatsby-image-wrapper {
    display: block;
  }

  .inline-gatsby-image-wrapper[src$=".gif"] {
    position: relative !important;
  }

  > img {
    width: auto !important;
    height: auto !important;
  }

  @media only screen and (max-width: 767px) {
    &.has-mobile-split-image > :last-child {
      display: none;
    }
  }

  @media only screen and (min-width: 48em) {
    &.has-mobile-split-image > :first-child {
      display: none;
    }

    ${({ objectFit, verticalAlignment }) =>
      objectFit === "contain" &&
      `align-self: ${ALIGN_SELF_PROPERTIES[verticalAlignment]};`};

    .inline-gatsby-image-wrapper {
      display: block;

      ${({ objectFit, objectPosition, focalPoint }) =>
        objectFit === "cover" &&
        `position: absolute !important;
        top: 0;
        bottom: 0;
        img {
          object-fit: ${objectFit} !important;
          object-position: ${
            focalPoint
              ? `${getMediaPosition(focalPoint)} !important`
              : objectPosition
              ? `${objectPosition} !important`
              : `center center`
          };
        }`}
    }
  }
`

function HeroSplitImage(props) {
  return <StyledHeroSplitImage {...props} />
}

export default HeroSplitImage
