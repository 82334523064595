import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { em } from "polished"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { CONTENT_POSITION_DICTIONARY } from "../../constants"
import { useImageByDatabaseId } from "../../hooks/useImageByDatabaseId"
import { StyledHeroContent } from "./HeroContent"

function getMediaPosition({ x = "0.5", y = "0.5" } = {}) {
  return `${Math.round(x * 100)}% ${Math.round(y * 100)}%`
}

// group together common styles for positioning our pseudo element that serves as our background image overlay
const setOverlay = () => `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  content: "";
`

const setStackOrder = () => `
  @media only screen and (max-width: 479px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const StyledMobileHeroBackground = styled(GatsbyImage)`
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;

  @media only screen and (min-width: 30em) {
    display: none;
  }
`

const StyledDesktopHeroBackground = styled(GatsbyImage)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
`

const StyledHeroInner = styled.div`
  position: relative;
  z-index: 1;
  padding: 0;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 479px) {
    ${({ mobileTextAlignment }) =>
      !!mobileTextAlignment && `text-align: ${mobileTextAlignment}`};

    ${({ mobileContentMinHeight, stackOnMobile }) =>
      !!mobileContentMinHeight &&
      !stackOnMobile &&
      `min-height: ${mobileContentMinHeight}`};

    align-items: ${({ mobileContentPosition }) =>
      mobileContentPosition &&
      CONTENT_POSITION_DICTIONARY[mobileContentPosition][0]};
    justify-content: ${({ mobileContentPosition }) =>
      mobileContentPosition &&
      CONTENT_POSITION_DICTIONARY[mobileContentPosition][1]};
  }

  @media only screen and (max-width: 767px) {
    ${StyledHeroContent}:only-child {
      max-width: none;
      height: 100%;
    }
  }

  @media only screen and (min-width: 48em) {
    gap: 1rem;
    padding: 3rem ${em(70)};
    margin: 0 auto;
    width: 100%;
    min-height: ${({ contentMinHeight }) =>
      contentMinHeight ? contentMinHeight : "600px"};

    align-items: ${({ contentPosition }) =>
      contentPosition && CONTENT_POSITION_DICTIONARY[contentPosition][0]};
    justify-content: ${({ contentPosition }) =>
      contentPosition && CONTENT_POSITION_DICTIONARY[contentPosition][1]};
  }

  @media only screen and (min-width: 1024px) {
    padding: 3rem ${em(94)};
  }
`

const StyledHero = styled.section`
  position: relative;
  background-image: none;

  ${({ reverseStack }) => reverseStack && setStackOrder()};

  &:before {
    ${({ overlayColor, hasBackgroundDim }) =>
      overlayColor && hasBackgroundDim && `background-color: ${overlayColor};`}
    ${({ customGradient, hasBackgroundDim }) =>
      customGradient &&
      hasBackgroundDim &&
      `background-image: ${customGradient};`}
    ${({ overlayColor, customGradient }) =>
      (overlayColor || customGradient) && setOverlay()}

    opacity: ${({ hasBackgroundDim, overlayColor, customGradient, dimRatio }) =>
      hasBackgroundDim && (overlayColor || customGradient) && dimRatio * 0.01};
  }

  @media only screen and (max-width: 479px) {
    &:before {
      ${({ mobileOverlayColor, stackOnMobile }) =>
        !stackOnMobile &&
        mobileOverlayColor &&
        `background-color: ${mobileOverlayColor};`}
      ${({ mobileCustomGradient, stackOnMobile }) =>
        !stackOnMobile &&
        mobileCustomGradient &&
        `background-image: ${mobileCustomGradient};`}
      ${({ mobileOverlayColor, mobileCustomGradient, stackOnMobile }) =>
        ((!stackOnMobile && mobileOverlayColor) || mobileCustomGradient) &&
        setOverlay()}
      opacity: ${({
        mobileOverlayColor,
        mobileCustomGradient,
        mobileDimRatio,
        stackOnMobile,
      }) =>
        ((!stackOnMobile && mobileOverlayColor) || mobileCustomGradient) &&
        mobileDimRatio * 0.01};
    }
  }

  ${StyledDesktopHeroBackground} {
    @media only screen and (max-width: 479px) {
      ${({ hasMobileImage }) => hasMobileImage && `display: none`};
      ${({ stackOnMobile }) =>
        stackOnMobile ? `position: relative` : `position: absolute`};
    }
  }

  ${StyledDesktopHeroBackground}, ${StyledMobileHeroBackground} {
    @media only screen and (max-width: 479px) {
      ${({ stackOnMobile }) =>
        stackOnMobile ? `position: relative` : `position: absolute`};
    }
  }
`

/**
 * Styled component used for rendering a parsed Gutenberg HeroStandard component
 *
 * @param {Object} props stringified object of component props provided by WordPress Gutenberg
 * @param {string} props.customGradient linear-gradient property used as the value for `background-image`
 * @param {number} props.dimRatio opacity level of the background image overlay on a scale of 0-100
 * @param {number} props.mediaId database ID of the background image if present
 * @param {string} props.contentPosition identifier for placing the inner right HeroContent on a specific side
 * @param {string} props.overlayColor color code of background image overlay
 *
 * @returns {Element}
 */
function HeroStandard({
  children,
  customGradient,
  dimRatio,
  mediaId,
  contentPosition,
  overlayColor,
  focalPoint,
  contentMinHeight,
  // mobile controls
  mobileDimRatio,
  mobileFocalPoint,
  mobileContentMinHeight,
  mobileContentPosition,
  mobileMediaId,
  mobileOverlayColor,
  mobileCustomGradient,
  mobilePadding,
  mobileMargin,
  mobileTextAlignment,
  stackOnMobile,
  reverseStack,
}) {
  const imageByDatabaseId = useImageByDatabaseId(mediaId)
  const image = getImage(imageByDatabaseId)

  const mobileImageByDatabaseId = useImageByDatabaseId(mobileMediaId)
  const mobileImage = getImage(mobileImageByDatabaseId)

  return (
    <StyledHero
      hasBackgroundDim={dimRatio !== 0}
      customGradient={customGradient}
      overlayColor={overlayColor}
      dimRatio={dimRatio}
      hasBackgroundImage={!!mediaId}
      hasMobileBackgroundDim={mobileDimRatio !== 0}
      mobileCustomGradient={mobileCustomGradient}
      mobileOverlayColor={mobileOverlayColor}
      mobileDimRatio={mobileDimRatio}
      hasMobileImage={!!mobileMediaId}
      stackOnMobile={stackOnMobile}
      reverseStack={reverseStack}
    >
      {mobileDimRatio !== 100 && mobileImage && (
        <StyledMobileHeroBackground
          image={mobileImage}
          alt=""
          objectPosition={getMediaPosition(mobileFocalPoint)}
        />
      )}
      {dimRatio !== 100 && image && (
        <StyledDesktopHeroBackground
          image={image}
          alt=""
          objectPosition={getMediaPosition(focalPoint)}
        />
      )}
      <StyledHeroInner
        contentMinHeight={contentMinHeight}
        contentPosition={contentPosition}
        // mobile controls
        mobileContentMinHeight={mobileContentMinHeight}
        mobileContentPosition={mobileContentPosition}
        mobileTextAlignment={mobileTextAlignment}
        stackOnMobile={stackOnMobile}
      >
        {children}
      </StyledHeroInner>
    </StyledHero>
  )
}

HeroStandard.defaultProps = {
  contentPosition: "center left",
}

HeroStandard.propTypes = {
  contentPosition: PropTypes.string,
}

export default HeroStandard
