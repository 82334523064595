import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useRef } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { parseHtml } from "../../utils/parseHtml"

const CarouselWrap = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -33px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 25px;
    height: 25px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 25px;
    height: 25px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-size: 14px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 25px;
    height: 25px;

    content: "•";
    text-align: center;

    opacity: 1;
    color: #fff;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: var(--color-pointer);
  }

  /* Slider */
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;
    user-select: none;

    -webkit-touch-callout: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }

  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`
const ArrowButton = styled.button`
  display: none;
  background-color: transparent;

  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
`

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`

const Container = styled.div`
  overflow-x: hidden;
  min-height: 500px;

  @media only screen and (min-width: 1024px) {
    width: 88%;
  }
`

const StyledCarouselItemContainer = styled.section`
  background-color: #fff;
  border-radius: 20px;
  margin: 16px 6px !important;
  padding: 30px 0px 14px 0px;
  margin: 0;
  width: 335px !important;
  height: 424px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 4px 16px 0px;

  @media only screen and (min-width: 768px) {
    margin: 16px 12px !important;
    width: 384px !important;
    height: 434px;
  }
`

const StyledReviewWrap = styled.div`
  > p {
    font-size: 14px;
    text-align: center;
    margin: 0px 20px 4px;
    color: var(--color-great-dane);
    line-height: 29px;

    @media only screen and (min-width: 768px) {
      margin: 0px 24px 4px;
    }
  }

  > figcaption {
    text-align: center;
    > cite {
      text-transform: uppercase;
      color: var(--color-great-dane);
      font-weight: var(--font-bold);
      font-style: normal;
      font-size: 14px;
      margin: 3px 0 0 0;
    }
  }
`

function TestimonialCarousel({ selectedTestimonials }) {
  const graphqlResult = useStaticQuery(graphql`
    {
      allWpTestimonial {
        nodes {
          databaseId
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 140, height: 140, placeholder: BLURRED)
                }
              }
            }
          }
          title
          testimonials {
            rating
          }
          content
        }
      }
    }
  `)

  const testimonials = graphqlResult?.allWpTestimonial?.nodes
  const selectedTestimonialIds = selectedTestimonials.map(
    selected => JSON.parse(selected).id
  )

  const activeSelectedTestimonials = selectedTestimonials?.length
    ? testimonials.filter(t => selectedTestimonialIds.includes(t.databaseId))
    : testimonials

  const slider = useRef()
  const settings = {
    dots: false,
    variableWidth: true,
    adaptiveHeight: true,
    infinite: true,
    focusOnSelect: true,
    slidesToShow: 1,
    centerMode: true,
    arrows: false,
    accessibility: true,
    swipeToSlide: true,
    initialSlide: 1,
    centerPadding: "350px",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          centerPadding: "280px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 735,
        settings: {
          slidesToShow: 1,
          centerPadding: "170px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          centerPadding: "15px",
        },
      },
    ],
  }

  return (
    <CarouselWrap>
      <CarouselContainer>
        <ArrowButton onClick={() => slider?.current?.slickPrev()}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.5 16.5H11.745L19.065 9.17996C19.65 8.59496 19.65 7.63496 19.065 7.04996C18.48 6.46496 17.535 6.46496 16.95 7.04996L7.06497 16.935C6.47997 17.52 6.47997 18.465 7.06497 19.05L16.95 28.935C17.535 29.52 18.48 29.52 19.065 28.935C19.65 28.35 19.65 27.405 19.065 26.82L11.745 19.5H28.5C29.325 19.5 30 18.825 30 18C30 17.175 29.325 16.5 28.5 16.5Z"
              fill="#004986"
            />
          </svg>
        </ArrowButton>
        <Container>
          <Slider ref={slider} {...settings}>
            {activeSelectedTestimonials.map(testimonial => {
              return (
                <StyledCarouselItemContainer key={testimonial.databaseId}>
                  <GatsbyImage
                    image={
                      testimonial.featuredImage.node.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    imgStyle={{
                      borderRadius: "90px",
                      border: "3px solid #3d87cb",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 4px 16px 0px",
                    }}
                    style={{
                      overflow: "visible",
                      marginBottom: "16px",
                    }}
                    alt={`Portrait of testimonial author ${testimonial.title}.`}
                  />
                  <div
                    aria-label={`${testimonial.testimonials.rating} out of 5 stars `}
                  >
                    {Array.from(
                      {
                        length: testimonial.testimonials.rating,
                      },
                      (v, i) => (
                        <svg
                          key={i}
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-label="Star rating"
                        >
                          <g clipPath="url(#clip0_2590:3816)">
                            <path
                              d="M10.76 17.224L15.9784 20.3802C16.9341 20.9586 18.1035 20.1036 17.8521 19.0222L16.4689 13.087L21.0837 9.08826C21.9262 8.35894 21.4735 6.97574 20.367 6.88772L14.2935 6.37216L11.9169 0.763905C11.4893 -0.254635 10.0307 -0.254635 9.60314 0.763905L7.22655 6.35958L1.15303 6.87514C0.0464724 6.96316 -0.406212 8.34636 0.436284 9.07569L5.05115 13.0744L3.66795 19.0096C3.41646 20.091 4.58589 20.9461 5.54156 20.3676L10.76 17.224Z"
                              fill=" #3d87cb"
                            />
                          </g>
                        </svg>
                      )
                    )}
                  </div>
                  <StyledReviewWrap>
                    {parseHtml(testimonial.content)}
                    <figcaption>
                      <cite>{testimonial.title}</cite>
                    </figcaption>
                  </StyledReviewWrap>
                </StyledCarouselItemContainer>
              )
            })}
          </Slider>
        </Container>
        <ArrowButton onClick={() => slider?.current?.slickNext()}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 19.5H24.255L16.935 26.82C16.35 27.405 16.35 28.365 16.935 28.95C17.52 29.535 18.465 29.535 19.05 28.95L28.935 19.065C29.52 18.48 29.52 17.535 28.935 16.95L19.065 7.04996C18.48 6.46496 17.535 6.46496 16.95 7.04996C16.365 7.63496 16.365 8.57996 16.95 9.16496L24.255 16.5H7.5C6.675 16.5 6 17.175 6 18C6 18.825 6.675 19.5 7.5 19.5Z"
              fill="#004986"
            />
          </svg>
        </ArrowButton>
      </CarouselContainer>
    </CarouselWrap>
  )
}

export default TestimonialCarousel
