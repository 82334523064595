import styled from "styled-components"

export const StyledBuyBox = styled.section`
  max-width: 580px;
`

export const StyledRatingsContainer = styled.div`
  height: 20px;
  margin-bottom: 20px;
`

export const StyledRatingsButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--color-irish-wolfhound);
`
