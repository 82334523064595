import React from "react"
import styled from "styled-components"

const StyledLogoFarm = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(calc(150px - 1rem), 1fr));
  padding: 0 24px;
  gap: 1rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(calc(200px - 1rem), 1fr));
  }

  .inline-gatsby-image-wrapper {
    > picture {
      > img {
        object-fit: contain !important;
      }
    }
  }
`

function LogoFarm({ children }) {
  return <StyledLogoFarm>{children}</StyledLogoFarm>
}

export default LogoFarm
