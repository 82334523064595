import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

export const StyledPill = styled.span`
  border: none;
  display: inline-block;
  padding: 0.25em 0.75em;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "var(--color-cool-gray)"};
  border-radius: 20px;
  box-shadow: none;
  font-size: 14px;
  font-weight: var(--font-bold);
  color: ${({ color }) => (color ? color : "inherit")};
  text-decoration: none;
`

export function Pill(props) {
  return <StyledPill {...props} />
}

Pill.propTypes = {
  children: PropTypes.node.isRequired,
}
