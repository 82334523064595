import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  PURCHASE_TYPE_ONETIME,
  PURCHASE_TYPE_SUBSCRIPTION,
} from "../../constants"
import { useStore } from "../../hooks/useStore"
import { getAttributedVariant } from "../../utils/getAttributedVariant"
import { getAttributeByKey } from "../../utils/getLineItemAttributes"
import { parseGid } from "../../utils/parseGid"
import Icon from "../Icon/Icon"
import { ScreenReaderText } from "../ScreenReaderText"
import Spinner from "../Spinner/Spinner"
import {
  StyledButton,
  StyledButtonMessage,
  StyledButtonWrap,
  StyledQuantityField,
  StyledQuantityFieldControl,
  StyledQuantityFieldInput,
} from "./StyledButton"

const handleAddToCart = (
  action = () => null,
  onAfterAction = () => null,
  redirectToCart = false
) => {
  return async e => {
    const result = await action(e)
    await onAfterAction(result)
    if (redirectToCart) {
      navigate("/cart")
    }
  }
}

function ButtonAddToCart({
  alignment,
  bundleId,
  discountCode,
  withLargeRadius,
  size,
  showQuantity,
  text,
  variant: DEFAULT_VARIANT,
  product,
  customAttributes,
  lineItemNotes,
  onAfterAction,
  interval,
  redirectToCart,
  isSubscription,
  purchaseType: DEFAULT_PURCHASE_TYPE,
  addonVariants,
  afterAddedText,
  defaultQuantity = 1,
  __dataLayer,
  hasViewItemDataLayer,
}) {
  const quantityNode = useRef()
  const [adding, setAdding] = useState(false)
  const [added, setAdded] = useState(false)
  const [quantity, setQuantity] = useState(defaultQuantity)
  const [purchaseType, setPurchaseType] = useState(DEFAULT_PURCHASE_TYPE)
  const [hasBundle, setHasBundle] = useState(false)
  const { checkout, addLineItems, getProductByGid, getVariantByGid } =
    useStore()

  const visibleVariant = getVariantByGid(DEFAULT_VARIANT.shopifyId)
  const visibleProduct = getProductByGid(
    product?.shopifyId ||
      DEFAULT_VARIANT?.product?.shopifyId ||
      DEFAULT_VARIANT.productId
  )

  const [checkoutContainsFreeTrial, setCheckoutContainsFreeTrial] =
    useState(false)

  // required to support standalone BUttonAddToCart where purchaseType is not determined
  // at runtime but rather at build time via Gutenberg props
  useEffect(() => {
    if (isSubscription !== undefined) {
      setPurchaseType(
        isSubscription ? PURCHASE_TYPE_SUBSCRIPTION : PURCHASE_TYPE_ONETIME
      )
    }
  }, [isSubscription, DEFAULT_PURCHASE_TYPE])

  useEffect(() => {
    // [].find returns the array element if found; otherwise undefined
    if (DEFAULT_VARIANT.allowOnlyOne) {
      const freeTrialExistsInCart = checkout.lineItems.find(
        item => item.title === "Clean Protein Dog Food - Free Trial Auto renew"
      )
      if (freeTrialExistsInCart !== undefined) {
        setCheckoutContainsFreeTrial(true)
      }
    }
  }, [checkout.lineItems, DEFAULT_VARIANT])

  useEffect(() => {
    if (bundleId) {
      const bundle = checkout.lineItems.find(lineItem => {
        const getAttributeValue = getAttributeByKey(lineItem.customAttributes)
        const bundle = getAttributeValue("bundle_id")
        return bundle === bundleId
      })

      setHasBundle(!!bundle)
    }
  }, [bundleId, checkout.lineItems])

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    if (
      hasViewItemDataLayer &&
      visibleProduct &&
      visibleVariant &&
      checkout?.currencyCode
    ) {
      const getAttributeValue = getAttributeByKey(visibleVariant.metafields)
      const visibleVariantSubscriptionPrice = getAttributeValue(
        "discount_variant_price"
      )

      // Elevar base datalayer for Viewed Products
      window.dataLayer.push({
        event: "dl_view_item",
        event_id: uuidv4(),
        ecommerce: {
          currencyCode: checkout.currencyCode,
          detail: {
            actionField: {
              list: "",
            },
            products: [
              {
                name: visibleProduct.title,
                id: (visibleVariant && visibleVariant.sku) || "",
                product_id: parseGid(visibleProduct.shopifyId) || "",
                variant_id: parseGid(visibleVariant.shopifyId) || "",
                image: visibleProduct?.featuredImage?.originalSrc || "",
                price:
                  purchaseType === PURCHASE_TYPE_SUBSCRIPTION
                    ? visibleVariantSubscriptionPrice
                    : visibleVariant.price,
                brand: visibleProduct.vendor,
                variant: visibleProduct.hasOnlyDefaultVariant
                  ? visibleProduct.title
                  : visibleVariant.displayName,
                category: visibleProduct.productType,
                inventory: visibleVariant.inventoryQuantity,
                list: "",
              },
            ],
          },
        },
      })
    }

    if (__dataLayer) {
      window.dataLayer.push({
        event: "onRenderButtonAddToCart",
        ...__dataLayer,
      })

      // Measure a view of product details. This example assumes the detail view occurs on pageload,
      // and also tracks a standard pageview of the details page.
      window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
      window.dataLayer.push({
        ecommerce: {
          detail: {
            products: [
              {
                name: __dataLayer.product_name,
                id: __dataLayer.product_ids[0],
                price: __dataLayer.line_item_value,
                category: __dataLayer.product_category,
                variant: __dataLayer.VariantName,
              },
            ],
          },
        },
      })
    }
  }, [
    hasViewItemDataLayer,
    purchaseType,
    visibleProduct,
    visibleVariant,
    checkout.currencyCode,
  ])

  useEffect(() => {
    setQuantity(defaultQuantity)
  }, [defaultQuantity])

  useEffect(() => {
    const timer = setTimeout(() => setAdded(false), 5000)
    return () => {
      clearTimeout(timer)
    }
  })

  const addVariantToCart = async e => {
    e.preventDefault()
    try {
      setAdding(true)

      const customAttributesWithStaticNotes = customAttributes.concat(
        ...(lineItemNotes?.length > 0
          ? [
              {
                key: "line_item_notes",
                value: lineItemNotes.trim(),
              },
            ]
          : []),
        ...(bundleId
          ? [
              {
                key: "bundle_id",
                value: bundleId,
              },
            ]
          : [])
      )

      const bundledProducts =
        addonVariants?.length && !hasBundle
          ? addonVariants.map(addon => {
              return getAttributedVariant(
                addon.product,
                addon,
                1,
                PURCHASE_TYPE_ONETIME,
                null,
                null,
                addon.customAttributes
              )
            })
          : []

      const result = await addLineItems(checkout.id, [
        getAttributedVariant(
          visibleProduct,
          visibleVariant,
          quantity,
          purchaseType,
          interval,
          discountCode,
          customAttributesWithStaticNotes
        ),
        ...bundledProducts,
      ])

      setAdding(false)
      setAdded(true)
      return result
    } catch (e) {
      console.error(e)
      setAdding(false)
      setAdded(false)
    }
  }

  const handleQuantityChange = e => {
    const quantity = e.target.value
    setQuantity(Number(quantity))
  }

  const getButtonText = () => {
    if (adding) {
      return (
        <StyledButtonMessage>
          <Spinner height={16} width={16} color="#ffffff" /> Adding...
        </StyledButtonMessage>
      )
    }

    if (!adding && added && afterAddedText) {
      return afterAddedText
    }

    return text
  }

  const handleAfterAddToCart = () => {
    window.dataLayer = window.dataLayer || []

    const getAttributeValue = getAttributeByKey(visibleVariant.metafields)
    const visibleVariantSubscriptionPrice = getAttributeValue(
      "discount_variant_price"
    )

    // Elevar base datalayer for Add to Cart
    window.dataLayer.push({
      event: "dl_add_to_cart",
      event_id: uuidv4(),
      ecommerce: {
        currencyCode: checkout.currencyCode,
        add: {
          actionField: {
            list: "",
          },
          products: [
            {
              name: visibleProduct.title,
              id: (visibleVariant && visibleVariant.sku) || "",
              product_id: parseGid(visibleProduct.shopifyId) || "",
              variant_id: parseGid(visibleVariant.shopifyId) || "",
              image: visibleProduct?.featuredImage?.originalSrc || "",
              price:
                purchaseType === PURCHASE_TYPE_SUBSCRIPTION
                  ? visibleVariantSubscriptionPrice
                  : visibleVariant.price,
              brand: visibleProduct.vendor,
              variant: visibleProduct.hasOnlyDefaultVariant
                ? visibleProduct.title
                : visibleVariant.displayName,
              category: visibleProduct.productType,
              quantity: quantity,
              list: "",
            },
          ],
        },
      },
    })

    if (__dataLayer) {
      window.dataLayer.push({
        event: "onClickButtonAddToCart",
        ...__dataLayer,
        line_item_quantity: quantity,
      })

      // Clear the previous ecommerce object.
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "addToCart",
        ecommerce: {
          add: {
            // 'add' actionFieldObject measures.
            products: [
              {
                //  adding a product to a shopping cart.
                name: __dataLayer.product_name,
                id: __dataLayer.product_ids[0],
                price: __dataLayer.line_item_value,
                category: __dataLayer.product_category,
                quantity: quantity,
              },
            ],
          },
        },
      })
    }

    onAfterAction()
  }

  return (
    <StyledButtonWrap
      size={size}
      alignment={alignment}
      showQuantity={showQuantity}
      withLargeRadius={withLargeRadius}
    >
      {showQuantity && (
        <StyledQuantityField>
          <ScreenReaderText>Product quantity</ScreenReaderText>
          <StyledQuantityFieldControl
            onClick={e => {
              e.preventDefault()
              const nextQuantity = quantity - 1
              setQuantity(nextQuantity >= 1 ? nextQuantity : 1)
            }}
          >
            <Icon name="subtraction" width={16} height={16} />
          </StyledQuantityFieldControl>
          <StyledQuantityFieldInput
            ref={quantityNode}
            type="number"
            min={1}
            pattern="[0-9]*"
            value={quantity}
            onChange={handleQuantityChange}
          />
          <StyledQuantityFieldControl
            onClick={e => {
              e.preventDefault()
              const nextQuantity = quantity + 1
              setQuantity(nextQuantity)
            }}
          >
            <Icon name="addition" width={16} height={16} />
          </StyledQuantityFieldControl>
        </StyledQuantityField>
      )}
      {redirectToCart ? (
        <StyledButton
          disabled={
            checkoutContainsFreeTrial && DEFAULT_VARIANT.allowOnlyOne
              ? true
              : false
          }
          className="add-to-cart-button"
          onClick={handleAddToCart(
            addVariantToCart,
            handleAfterAddToCart,
            redirectToCart
          )}
          aria-label={adding ? "Adding product to cart" : void 0}
        >
          {getButtonText()}
        </StyledButton>
      ) : (
        <StyledButton
          disabled={
            checkoutContainsFreeTrial && DEFAULT_VARIANT.allowOnlyOne
              ? true
              : false
          }
          className="add-to-cart-button"
          onClick={handleAddToCart(
            addVariantToCart,
            handleAfterAddToCart,
            redirectToCart
          )}
          aria-label={adding ? "Adding product to cart" : void 0}
        >
          {getButtonText()}
        </StyledButton>
      )}
    </StyledButtonWrap>
  )
}

ButtonAddToCart.defaultProps = {
  alignment: "left",
  discountCode: {},
  withLargeRadius: false,
  showQuantity: false,
  customAttributes: [],
  onAfterAction: () => null,
  size: "normal",
  hasViewItemDataLayer: true,
  addonVariants: [],
  purchaseType: PURCHASE_TYPE_SUBSCRIPTION,
  interval: "4 weeks",
}

ButtonAddToCart.propTypes = {
  addonVariants: PropTypes.array,
  alignment: PropTypes.oneOf(["left", "right", "center"]),
  discountCode: PropTypes.shape({
    code: PropTypes.string,
    value: PropTypes.number,
  }),
  showQuantity: PropTypes.bool,
  withLargeRadius: PropTypes.bool,
  hasViewItemDataLayer: PropTypes.bool,
  text: PropTypes.string.isRequired,
  afterAddedText: PropTypes.string,
  size: PropTypes.string,
  customAttributes: PropTypes.array,
  interval: PropTypes.string,
  purchaseType: PropTypes.oneOf([
    PURCHASE_TYPE_SUBSCRIPTION,
    PURCHASE_TYPE_ONETIME,
  ]),
  product: PropTypes.shape({
    shopifyId: PropTypes.string.isRequired,
  }),
  onAfterAction: PropTypes.func,
}

export default ButtonAddToCart
