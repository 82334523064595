import { em } from "polished"
import React from "react"
import styled from "styled-components"
import { columns } from "../../utils/columns"

export const StyledBanner = styled.div`
  text-align: center;
  padding: 1em;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : `var(--color-italian-greyhound)`};
  width: 100%;

  * {
    margin: 0;
  }

  > div:last-child {
    display: none;
  }

  @media only screen and (min-width: 48em) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: ${columns(10)}px;
    gap: ${em(24)};
    justify-content: space-between;
    margin: 0 auto;

    align-items: center;

    > p {
      flex: 1;
      text-align: initial;
    }

    > div:last-child {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      border-radius: calc(1em + 2px);
      padding: 2px 1em;
      flex: 1;
    }
  }
`

function Banner(props) {
  return <StyledBanner {...props} />
}

export default Banner
