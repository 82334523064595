import React from "react"
import styled from "styled-components"

const StyledContentFeature = styled.section`
  position: relative;
`

const StyledContentFeatureInner = styled.div`
  text-align: center;
  width: 100%;
  max-width: 920px;

  @media only screen and (min-width: 768px) {
    padding: 3rem 3rem;
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px) {
    > div:nth-child(2) {
      padding: 0 2rem;
    }
  }
`

/**
 * Styled component used for rendering a parsed Gutenberg ContentFeature component
 *
 * @param {Object} props stringified object of component props provided by WordPress Gutenberg
 *
 * @returns {Element}
 */
function ContentFeature({ children }) {
  return (
    <StyledContentFeature>
      <StyledContentFeatureInner>{children}</StyledContentFeatureInner>
    </StyledContentFeature>
  )
}

export default ContentFeature
