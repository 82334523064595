import { em } from "polished"
import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  :root {
    --font-normal: 400;
    --font-bold: 700;
    --font-black: 800;
    --color-cool-gray: #e4e6e7;
    --color-gray: #d8d8d8;
    --color-blue: #3561a3;
    --color-navy: #1110a2;
    --color-earth: #512f1a;
    --color-beet: #913053;
    --color-red: #DE002A;
    --color-forest: #133f25;
    --border-radius: 9px;
    --body-font-stack:  "Gotham", Arial, sans-serif;

    --color-great-dane: #13294b;
    --color-ivory: #FBF7ED;
    --color-cameo: #f3ead3;
    --color-irish-wolfhound: #004986;
    --color-light-blue: #D2E4EA;
    --color-wild-blue: #0005f4;
    --color-gold: #c99728;
    --color-terracotta: #c96825;
    --color-beet: #9b2653;
    --color-green: #54BB53;
    --color-dark-green: #22922D;
    --color-staffie: #3d87cb;
    --color-pointer: #9bb8d3;
    --color-italian-greyhound: #c6dae7;
    --color-light-blue: #D2E4EA;
  }

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    background-color: #fbfbfb;
    box-sizing: border-box;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    line-height: 1.6;
    font-family: var(--body-font-stack);
    color: var(--color-great-dane);
    font-weight: var(--font-normal);
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: var(--body-font-stack);
    color: var(--color-irish-wolfhound);
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 0 0 8px;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    line-height: 1.2;
  }

  h1,
  .h1,
  h2,
  .h2 {
    font-size: ${em(32)};

    @media only screen and (min-width: 48em) {
      font-size: ${em(40)};
    }
  }

  h1, .h1 {
    font-weight: var(--font-black);
  }

  h2, 
  .h2, 
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-weight: var(--font-bold);
  }

  h3,
  .h3 {
    font-size: ${em(24)};

    @media only screen and (min-width: 48em) {
      font-size: ${em(32)};
    }
  }

  h4,
  .h4 {
    font-size: ${em(20)};
    @media only screen and (min-width: 48em) {
      font-size: ${em(24)};
    }
  }

  h5,
  .h5 {
    font-size: ${em(18)}; 
    
    @media only screen and (min-width: 48em) {
      font-size: ${em(20)};
    }
  }

  h6,
  .h6 {
    font-size: ${em(14)};
    font-weight: var(--font-bold);
  }

  ul ul,
  ul, ol,
  ol ol,
  ol ul {
    margin: 1rem 0;
    padding-left: 2rem;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > div:not([class]) > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font-family: inherit; 
    font-size: 100%; 
    line-height: 1.15;
    margin: 0;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  .wp-block-image {
    margin: 0 0 1em 0;

    img {
      height: auto;
      max-width: 100%;
      vertical-align: bottom;
    }

    &:not(.is-style-rounded) {
      > a,
      img {
        border-radius: inherit;
      }
    }

    &.aligncenter {
      text-align: center;
    }

    &.alignfull img,
    &.alignwide img {
      height: auto;
      width: 100%;
    }

    .alignleft,
    .alignright,
    .aligncenter {
       display: table;

      > figcaption {
        display: table-caption;
        caption-side: bottom;
      }
    }

    .alignleft {
      /*rtl:ignore*/
      float: left;
      /*rtl:ignore*/
      margin-left: 0;
      margin-right: 1em;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    .alignright {
      /*rtl:ignore*/
      float: right;
      /*rtl:ignore*/
      margin-right: 0;
      margin-left: 1em;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    .aligncenter {
      margin-left: auto;
      margin-right: auto;
    }


    // Variations
    &.is-style-rounded img {
      // We use an absolute pixel to prevent the oval shape that a value of 50% would give
      // to rectangular images. A pill-shape is better than otherwise.
      border-radius: 9999px;
    }

    // The following variation is deprecated.
    // The CSS is kept here for the time being, to support blocks using the old variation.
    &.is-style-circle-mask img {
      // We use an absolute pixel to prevent the oval shape that a value of 50% would give
      // to rectangular images. A pill-shape is better than otherwise.
      border-radius: 9999px;

      // If a browser supports it, we will switch to using a circular SVG mask.
      // The stylelint override is necessary to use the SVG inline here.
      @supports (mask-image: none) or (-webkit-mask-image: none) {
        /* stylelint-disable */
        mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
        /* stylelint-enable */
        mask-mode: alpha;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        border-radius: 0;
      }
    }
  }

  .wp-block-image figure {
    margin: 0;
  }

  figure.wp-block-image {
    .inline-gatsby-image-wrapper img {
      object-fit: contain !important;
    }
  }

  /* WP Block Embeds */
  .wp-block-embed {
    margin: 0;

    iframe {
      max-width: 100%;
    }

    &__wrapper {
      position: relative;
    }
  }

  .aligncenter {
    text-align: center;
  }

  .alignright {
    text-align: right;
  }

  .alignright {
    text-align: left;
  }

  .has-text-align-left {
    text-align: left;
  }

  .has-text-align-right {
    text-align: right;
  }

  .has-text-align-center {
    text-align: center;
  }

  .has-text-color.has-great-dane-color[class],
  .has-inline-color.has-great-dane-color[class],
  .has-great-dane-color[class] {
    color: var(--color-great-dane);
  }
  .has-text-color.has-ivory-color[class],
  .has-inline-color.has-ivory-color[class],
  .has-ivory-color[class] {
    color: var(--color-ivory);
  }
  .has-text-color.has-cameo-color[class],
  .has-inline-color.has-cameo-color[class],
  .has-cameo-color[class] {
    color: var(--color-cameo);
  }
  .has-text-color.has-irish-wolfhound-color[class],
  .has-inline-color.has-irish-wolfhound-color[class],
  .has-irish-wolfhound-color[class] {
    color: var(--color-irish-wolfhound);
  }
  .has-text-color.has-light-blue-color[class],
  .has-inline-color.has-light-blue-color[class],
  .has-light-blue-color[class] {
    color: var(--color-light-blue);
  }
  .has-text-color.has-wild-blue-color[class],
  .has-inline-color.has-wild-blue-color[class],
  .has-wild-blue-color[class] {
    color: var(--color-wild-blue);
  }
  .has-text-color.has-gold-color[class],
  .has-inline-color.has-gold-color[class],
  .has-gold-color[class] {
    color: var(--color-gold);
  }
  .has-text-color.has-terracotta-color[class],
  .has-inline-color.has-terracotta-color[class],
  .has-terracotta-color[class] {
    color: var(--color-terracotta);
  }
  .has-text-color.has-red-color[class],
  .has-inline-color.has-red-color[class],
  .has-red-color[class] {
    color: var(--color-red);
  }
  .has-text-color.has-beet-color[class],
  .has-inline-color.has-beet-color[class],
  .has-beet-color[class] {
    color: var(--color-beet);
  }
  .has-text-color.has-dark-green-color[class],
  .has-inline-color.has-dark-green-color[class],
  .has-dark-green-color[class] {
    color: var(--color-dark-green);
  }
  .has-text-color.has-green-color[class],
  .has-inline-color.has-green-color[class],
  .has-green-color[class] {
    color: var(--color-green);
  }
  .has-text-color.has-staffie-color[class],
  .has-inline-color.has-staffie-color[class],
  .has-staffie-color[class] {
    color: var(--color-staffie);
  }
  .has-text-color.has-pointer-color[class],
  .has-inline-color.has-pointer-color[class],
  .has-pointer-color[class] {
    color: var(--color-pointer);
  }
  .has-text-color.has-italian-greyhound-color[class],
  .has-inline-color.has-italian-greyhound-color[class],
  .has-italian-greyhound-color[class] {
    color: var(--color-italian-greyhound);
  }

  .has-text-color.has-white-color[class],
  .has-inline-color.has-white-color[class],
  .has-white-color[class] {
    color: #ffffff;
  }

  table {
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 2.25em;
    width: 100%;
  }

  tr {
    border-bottom: 1px solid var(--color-italian-greyhound);
  }

  td,
  th {
    font-size: 1em;
    border: 0;
    padding: 0.5em 0;

    &:not(:last-child) {
      padding-right: 0.25em;
    }

    &:not(:first-child) {
      padding-left: 0.25em;
    }
  }

  .wp-block-embed {
    &.is-type-video {
      margin: 0;
    }
  }

  /* Add responsiveness to embeds with aspect ratios. */
  .wp-has-aspect-ratio,
  .wp-block-embed-wistia-inc  {
    .wp-block-embed__wrapper::before {
      content: "";
      display: block;
      padding-top: 50%; // Default to 2:1 aspect ratio.
    }

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  /* For wistia embeds, default to full width 16:9 ratio */
  .wp-block-embed-wistia-inc .wp-block-embed__wrapper::before {
    padding-top: 56.25%; // 9 / 16 * 100
  }

  .wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
    padding-top: 42.85%; // 9 / 21 * 100
  }

  .wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
    padding-top: 50%; // 9 / 18 * 100
  }

  .wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
    padding-top: 56.25%; // 9 / 16 * 100
  }

  .wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
    padding-top: 75%; // 3 / 4 * 100
  }

  .wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
    padding-top: 100%; // 1 / 1 * 100
  }

  .wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
    padding-top: 177.77%; // 16 / 9 * 100
  }

  .wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
    padding-top: 200%; // 2 / 1 * 100
  }

  .disabled-link {
    pointer-events: none;
  }
`

export default GlobalStyles
