import parse, { attributesToProps, domToReact } from "html-react-parser"
import * as React from "react"
import * as Components from "../components"

/**
 * Determines whether an HTML DOM node has the reserved data attribute, `data-wildearth-component`
 *
 * This is useful for signalling to Gatsby that it needs to be replaced, from basic HTML provided by WordPres, to React
 *
 * @param {Element} node current dom node
 * @returns {boolean} true if the current dom node is meant to be parsed into a Styled Component or React component, otherwise false
 */
function isWildEarthComponent(node) {
  return node?.attribs["data-wildearth-component"]
}

/**
 * Parse the stringified JSON that is passed from WordPress Gutenberg to Gatsby via data attributes
 *
 * @param {Element} node current dom node
 * @returns {Object}
 */
function getPropsFromGutenbergBlock(node) {
  return JSON.parse(node.attribs["data-wildearth-component-props"] || {})
}

const parseHtmlToReact = (html, components) => {
  if (!html) {
    return
  }

  const options = {
    replace: node => {
      const { type, attribs, children } = node

      if (type !== "tag") {
        return
      }

      if (!isWildEarthComponent(node)) {
        return
      }

      const data = getPropsFromGutenbergBlock(node)
      const componentName = data?.__componentName

      if (!componentName) {
        console.error(
          `Wild Earth Gutenberg Block is missing the __componentName property.`
        )

        return
      }

      if (!components[componentName] && typeof window !== `undefined`) {
        console.error(`Couldn't find component for ${componentName}`)
      }

      const Component = components[componentName]
      const props = { ...attributesToProps(attribs), ...data }

      delete props["data-wildearth-component"]
      delete props["data-wildearth-component-props"]

      // recursively traverse through DOM and translate any nested Gutenberg Blocks into Styled Components as necessary
      if (Component && node?.children?.length) {
        return <Component {...props}>{domToReact(children, options)}</Component>
      } else if (Component) {
        return <Component {...props} />
      }

      return
    },
  }

  const Component = parse(html, options)

  return Component
}

export const parseHtml = (html, overrideComponents) =>
  parseHtmlToReact(html, {
    ...Components,
    ...overrideComponents,
  })
