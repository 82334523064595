import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { CONTENT_POSITION_DICTIONARY } from "../../constants"
import { getBoxStyles } from "../../utils/getBoxStyles"

export const StyledContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: ${({ contentPosition }) =>
    CONTENT_POSITION_DICTIONARY[contentPosition][0]};
  justify-content: ${({ contentPosition }) =>
    CONTENT_POSITION_DICTIONARY[contentPosition][1]};

  ${({ customGradient }) =>
    customGradient && `background-image: ${customGradient}`};

  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};

  ${({ contentMinHeight }) =>
    contentMinHeight && `min-height: ${contentMinHeight}`};

  ${({ margin }) =>
    Object.values(margin)?.length > 0 && getBoxStyles(margin, "margin")}

  > div {
    width: 100%;
    ${({ contentMaxWidth }) =>
      contentMaxWidth && `max-width: ${contentMaxWidth}`};

    ${({ padding }) =>
      Object.values(padding)?.length > 0 && getBoxStyles(padding, "padding")}
  }
`

function Container({
  children,
  contentPosition,
  customGradient,
  margin,
  padding,
  contentMinHeight,
  contentMaxWidth,
  backgroundColor,
}) {
  return (
    <StyledContainer
      contentPosition={contentPosition}
      margin={margin}
      padding={padding}
      customGradient={customGradient}
      contentMinHeight={contentMinHeight}
      contentMaxWidth={contentMaxWidth}
      backgroundColor={backgroundColor}
    >
      <div>{children}</div>
    </StyledContainer>
  )
}
Container.defaultProps = {
  padding: {},
  margin: {},
}

Container.propTypes = {
  margin: PropTypes.shape({
    left: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
  }),
  padding: PropTypes.shape({
    left: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
  }),
}

export default Container
