import { em } from "polished"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import styled from "styled-components"
import { StyledButton } from "../Buttons/StyledButton"
import Icon from "../Icon/Icon"
import { ScreenReaderText } from "../ScreenReaderText"
import Modal from "./Modal"

const StyledIconButton = styled(StyledButton)`
  padding: 0.275em;
  background-color: transparent;
  color: var(--color-staffie);
  border: 0;
  display: inline-flex;
  align-items: center;
  margin: ${em(18)} 0;
  border-radius: 2em;
  text-align: left;

  &:focus {
    background-color: #fff;
  }

  &:hover {
    background-color: #fff;
    color: var(--color-great-dane);
  }

  > svg {
    margin-right: 1em;
    display: inline-block;
    flex-shrink: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledRecommendationButton = styled(StyledIconButton)`
  padding: 0;
  margin: 0;
  font-weight: var(--font-normal);
`

const StyledModalHeader = styled.header`
  text-align: center;
`

const StyledModalContent = styled.div`
  max-width: 375px;
  margin-inline: auto;

  table th,
  table td {
    text-align: left;
  }
`

function ModalSubscriptionCalculator({ handleChangeSubscription }) {
  const modal = useRef()

  const handleClick = e => {
    const { quantity, interval, size } = e.currentTarget.dataset
    handleChangeSubscription(quantity, interval, size)
    modal.current.close()
  }

  const servings = [
    [
      "2 - 5 lb",
      <>
        <span aria-label="one fourth of a cup">&frac14; </span>-
        <span aria-label="one half of a cup"> &frac12;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="1 small bag per 6 weeks"
          data-quantity="1"
          data-interval="6 weeks"
          data-size="Small Bag (4lb)"
          {...props}
        >
          1 Sm Bag / 6 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "5 - 10 lb",
      <>
        <span aria-label="one half of a cup">&frac12; </span>-
        <span aria-label="three quarters of a cup"> &frac34;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="1 small bag per 4 weeks"
          data-quantity="1"
          data-interval="4 weeks"
          data-size="Small Bag (4lb)"
          {...props}
        >
          1 Sm Bag / 4 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "10 - 20 lb",
      <>
        <span aria-label="three quarters of a cup">&frac34; </span>-
        <span aria-label="one and one half of a cup"> 1&frac12;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="1 small bag per 4 weeks"
          data-quantity="1"
          data-interval="4 weeks"
          data-size="Small Bag (4lb)"
          {...props}
        >
          1 Sm Bag / 4 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "20 - 30 lb",
      <>
        <span aria-label="one and one half of a cup">1&frac12; </span>-
        <span aria-label="one and three quarters of a cup"> 1&frac34;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="1 large bag per 6 weeks"
          data-quantity="1"
          data-interval="6 weeks"
          data-size="Large Bag (18lb)"
          {...props}
        >
          1 Lg Bag / 6 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "30 - 40 lb",
      <>
        <span aria-label="one and three quarters of a cup">1&frac34; </span>-
        <span aria-label="two and a half cups"> 2&frac12;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="1 large bag per 5 weeks"
          data-quantity="1"
          data-interval="5 weeks"
          data-size="Large Bag (18lb)"
          {...props}
        >
          1 Lg Bag / 5 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "40 - 50 lb",
      <>
        <span aria-label="two and a quarter cups">2&frac14; </span>-
        <span aria-label="two and three quarters of a cup"> 2&frac34;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="1 large bag per 4 weeks"
          data-quantity="1"
          data-interval="4 weeks"
          data-size="Large Bag (18lb)"
          {...props}
        >
          1 Lg Bag / 4 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "50 – 60 lb",
      <>
        <span aria-label="two and three quarters of a cup">2&frac34; </span>-
        <span aria-label="three and a quarter of a cup"> 3&frac14;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="1 large bag per 3 weeks"
          data-quantity="1"
          data-interval="3 weeks"
          data-size="Large Bag (18lb)"
          {...props}
        >
          1 Lg Bag / 3 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "60 – 70 lb",
      <>
        <span aria-label="three and a quarter of a cup">3&frac14; </span>-
        <span aria-label="three and a half of a cup"> 3&frac12;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="1 large bag per 3 weeks"
          data-quantity="1"
          data-interval="3 weeks"
          data-size="Large Bag (18lb)"
          {...props}
        >
          1 Lg Bag / 3 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "70 – 80 lb",
      <>
        <span aria-label="three and a half of a cup">3&frac12; </span>-
        <span aria-label="four cups"> 4</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="1 large bag per 3 weeks"
          data-quantity="1"
          data-interval="3 weeks"
          data-size="Large Bag (18lb)"
          {...props}
        >
          1 Lg Bag / 3 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "80 – 90 lb",
      <>
        <span aria-label="four cups">4 </span>-
        <span aria-label="four and a quarter of a cup"> 4&frac14;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="2 large bag per 5 weeks"
          data-quantity="2"
          data-interval="5 weeks"
          data-size="Large Bag (18lb)"
          {...props}
        >
          2 Lg Bag / 5 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "90 – 100 lb",
      <>
        <span aria-label="four and a quarter of a cup">4&frac14; </span>-
        <span aria-label="four and a half of a cup"> 4&frac12;</span>
      </>,
      props => (
        <StyledRecommendationButton
          aria-label="2 large bag per 4 weeks"
          data-quantity="2"
          data-interval="4 weeks"
          data-size="Large Bag (18lb)"
          {...props}
        >
          2 Lg Bag / 4 Weeks
        </StyledRecommendationButton>
      ),
    ],
    [
      "Over 100 lb",
      <>
        <span aria-label="four and a half of a cup plus a quarter cup">
          4&frac12; + &frac14;{" "}
        </span>
        <span>per each additional 10 lbs</span>
      </>,
    ],
  ]

  return (
    <>
      <StyledIconButton type="button" onClick={() => modal.current.open()}>
        <Icon name="info" />{" "}
        <span>What Size &amp; Frequency is right for me?</span>
      </StyledIconButton>
      <Modal ref={modal} maxWidth="690px">
        <StyledModalHeader>
          <h2>Size & Frequency</h2>
          <p>Select the size and frequency that's right for you</p>
        </StyledModalHeader>
        <StyledModalContent>
          <table>
            <thead>
              <tr>
                <th scope="col">Weight</th>
                <th scope="col">
                  Cups / Day <ScreenReaderText>(Cups per day)</ScreenReaderText>
                </th>
                <th scope="col">Recommendation</th>
              </tr>
            </thead>
            <tbody>
              {servings.map(([weight, serving, RecommendationButton]) => {
                return (
                  <tr key={weight}>
                    <td>{weight}</td>
                    <td colSpan={!RecommendationButton ? "2" : undefined}>
                      {serving}
                    </td>
                    {RecommendationButton && (
                      <td>
                        <RecommendationButton onClick={handleClick} />
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </StyledModalContent>
      </Modal>
    </>
  )
}

ModalSubscriptionCalculator.propTypes = {
  handleChangeSubscription: PropTypes.func.isRequired,
}

export default ModalSubscriptionCalculator
