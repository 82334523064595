import React from "react"
import styled from "styled-components"

const StyledBrochure = styled.section`
  margin: 0 auto;
  max-width: 1260px;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    > div {
      ${({ mobilePanelMinHeight }) =>
        mobilePanelMinHeight && `min-height: ${mobilePanelMinHeight}`};
    }
  }

  @media only screen and (min-width: 64em) {
    display: grid;
    grid-template-columns: repeat(2, minmax(375px, 1fr));
    padding: 0 24px;
    gap: 24px;

    > div {
      ${({ panelMinHeight }) =>
        panelMinHeight && `min-height: ${panelMinHeight}`};
    }
  }
`

function Brochure({ children, panelMinHeight, mobilePanelMinHeight }) {
  return (
    <StyledBrochure
      panelMinHeight={panelMinHeight}
      mobilePanelMinHeight={mobilePanelMinHeight}
    >
      {children}
    </StyledBrochure>
  )
}

export default Brochure
