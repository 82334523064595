import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import { parse } from "query-string"
import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import { parseGid } from "../../utils/parseGid"
import ArrowLeft from "./ArrowLeft"
import ArrowRight from "./ArrowRight"
import GalleryImage from "./GalleryImage"

const fadeIn = keyframes`
  from {
    opacity: 0;
    background-color: white;
  }
  to {
    opacity: 1;
    background-color: white;
  }
`

const StyledGallery = styled.section`
  max-width: 579px;
  margin-inline: auto;
`

const StyledGalleryListWrap = styled.div`
  position: relative;
`

const StyledGalleryList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
`

const StyledGalleryThumbnailList = styled(StyledGalleryList)`
  @media only screen and (max-width: 30em) {
    display: none;
  }
  margin-top: 24px;
  gap: 24px;
`

const StyledNavigationArrows = styled.button`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;

  &:focus {
    outline: none;
    box-shadow: var(--color-great-dane) 0 0 0 2px;
  }

  &:first-of-type {
    left: 30px;
  }

  &:last-of-type {
    right: 30px;
  }

  > svg {
    display: block;
  }
`

function mapOrder(arr, order, key) {
  arr.sort(function (a, b) {
    var A = a[key],
      B = b[key]

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    } else {
      return -1
    }
  })

  return arr
}

function Gallery({ images }) {
  const [focused, setFocused] = useState(false)
  const location = useLocation()
  const gqlResult = useStaticQuery(graphql`
    {
      allWpMediaItem(
        filter: {
          mediaCategories: {
            nodes: { elemMatch: { slug: { eq: "product_image" } } }
          }
        }
      ) {
        nodes {
          id
          databaseId
          focusnail: localFile {
            childImageSharp {
              gatsbyImageData(width: 579, height: 579, placeholder: BLURRED)
            }
          }
          thumbnail: localFile {
            childImageSharp {
              gatsbyImageData(width: 100, height: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  const params = parse(location.search)

  useEffect(() => {
    if (params.variant) {
      const firstOccurrence = images.find(
        image =>
          parseGid(image?.attributes?.productVariant?.shopifyId) ===
          params.variant
      )

      if (firstOccurrence) {
        setFocused(firstOccurrence.attributes.mediaId)
      }
    }
  }, [params.variant])

  // we need to provide a data structure where we can access the appropriate image attributes at O(1) cost
  const galleryImageAttributes = images.reduce((acc, curr) => {
    if (!acc[curr.id]) {
      acc[curr.id] = curr.attributes
    }

    return acc
  }, {})

  const galleryImageDatabaseIds = images.map(image => image.attributes.mediaId)

  let nodes = gqlResult?.allWpMediaItem?.nodes || []
  nodes = nodes.filter(n => galleryImageDatabaseIds.includes(n.databaseId))
  nodes = mapOrder(nodes, galleryImageDatabaseIds, "databaseId")

  const focusnails = nodes.map((f, i) => {
    const props = galleryImageAttributes[f.databaseId]
    const isFocusedThumb = focused && focused === f.databaseId

    const isFocusedVariant =
      focused &&
      props.productVariant &&
      focused === parseGid(props.productVariant.shopifyId)

    const isFocusedDefault =
      !isFocusedThumb && !isFocusedVariant && i === 0 && !focused

    return (
      <li
        key={f.id}
        css={`
          animation: ${fadeIn} 500ms linear forwards;
        `}
        style={{
          display:
            isFocusedThumb || isFocusedVariant || isFocusedDefault
              ? "block"
              : "none",
        }}
      >
        <GalleryImage image={f.focusnail} {...props} />
      </li>
    )
  })

  const thumbnails = nodes.map(t => (
    <li key={t.id}>
      <GalleryImage
        image={t.thumbnail}
        handleClick={() => setFocused(t.databaseId)}
        {...galleryImageAttributes[t.databaseId]}
      />
    </li>
  ))

  return (
    <StyledGallery>
      <StyledGalleryListWrap>
        <StyledGalleryList>{focusnails}</StyledGalleryList>
        <StyledNavigationArrows
          onClick={() => {
            const index = galleryImageDatabaseIds.indexOf(focused)
            const end = galleryImageDatabaseIds.length - 1
            const prev = index - 1

            const target = galleryImageDatabaseIds[prev < 0 ? end : prev]
            setFocused(target)
          }}
          aria-label="Previous gallery image"
        >
          <ArrowLeft />
        </StyledNavigationArrows>
        <StyledNavigationArrows
          onClick={() => {
            const index = galleryImageDatabaseIds.indexOf(focused)
            const next = index < 0 ? 1 : index + 1

            const target =
              galleryImageDatabaseIds[
                next >= galleryImageDatabaseIds.length ? 0 : next
              ]

            setFocused(target)
          }}
          aria-label="Next gallery image"
        >
          <ArrowRight />
        </StyledNavigationArrows>
      </StyledGalleryListWrap>
      <StyledGalleryThumbnailList>{thumbnails}</StyledGalleryThumbnailList>
    </StyledGallery>
  )
}

export default Gallery
