import React from "react"
import styled from "styled-components"

const StyledLogoFarmItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > figure {
    width: 150px;
  }
`

function LogoFarmItem({ children }) {
  return <StyledLogoFarmItem>{children}</StyledLogoFarmItem>
}

export default LogoFarmItem
