import PropTypes from "prop-types"
import * as React from "react"
import { Container } from "../components/Container"
import Footer from "../components/Footer"
import Header from "../components/header"
import "./fonts.css"
import GlobalStyles from "./GlobalStyles"

const SiteLayout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Container>
        <Header />
        {children}
        <Footer />
      </Container>
    </>
  )
}

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SiteLayout
