import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { getBoxStyles } from "../../utils/getBoxStyles"
import { StyledHeroContent } from "./HeroContent"

const StyledHeroSplit = styled.section`
  position: relative;

  @media only screen and (max-width: 767px) {
    ${({ mobileMargin }) =>
      Object.values(mobileMargin)?.length > 0 &&
      getBoxStyles(mobileMargin, "margin")}
  }

  @media only screen and (min-width: 48em) {
    ${({ margin }) =>
      Object.values(margin)?.length > 0 && getBoxStyles(margin, "margin")}
  }
`

const setStackOrder = () => `
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const StyledHeroSplitInner = styled.div`
  ${({ contentMinHeight }) =>
    contentMinHeight && `min-height: ${contentMinHeight}`};

  ${StyledHeroContent} {
    padding: 24px;
  }

  ${({ reverseStack }) => reverseStack && setStackOrder()};

  @media only screen and (min-width: 48em) {
    display: grid;
    grid-template-columns: 50% 50%;
  }
`

/**
 * Styled component used for rendering a parsed Gutenberg ContentFeature component
 *
 * @param {Object} props stringified object of component props provided by WordPress Gutenberg
 *
 * @returns {Element}
 */
function HeroSplit({
  children,
  contentPosition,
  contentMinHeight,
  margin,
  // mobile controls
  reverseStack,
  mobileMargin,
}) {
  return (
    <StyledHeroSplit margin={margin} mobileMargin={mobileMargin}>
      <StyledHeroSplitInner
        contentMinHeight={contentMinHeight}
        contentPosition={contentPosition}
        reverseStack={reverseStack}
      >
        {children}
      </StyledHeroSplitInner>
    </StyledHeroSplit>
  )
}

HeroSplit.defaultProps = {
  margin: {},
  mobileMargin: {},
}

HeroSplit.propTypes = {
  margin: PropTypes.shape({
    left: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
  }),
  mobileMargin: PropTypes.shape({
    left: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
  }),
}

export default HeroSplit
