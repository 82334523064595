import { em } from "polished"
import styled from "styled-components"

export const StyledAccordionToggle = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  > svg {
    flex-shrink: 0;
  }
`

export const StyledAccordionHeading = styled.h2`
  font-size: ${em(18)};
  margin: 0;
`

export const StyledAccordionContent = styled.div`
  color: ${({ textColor }) => textColor};
  padding: ${({ isOpen }) => (isOpen ? `30px 0` : `0`)};
  max-height: ${({ isOpen }) => (isOpen ? `100%` : `0`)};
  overflow: hidden;
`

export const StyledAccordionHeader = styled.header`
  padding: ${({ isOpen }) => (isOpen ? `0` : `0 0 30px`)};
  > button,
  ${StyledAccordionHeading} {
    color: ${({ textColor }) => textColor};
  }
`

export const StyledAccordion = styled.section`
  border-bottom: 2px solid ${({ textColor }) => textColor};
  overflow: hidden;

  margin: 2em 0;

  &:not(:last-of-type) {
    margin-bottom: 2em;
  }
`
