import { em } from "polished"
import styled from "styled-components"

export const StyledEmailInput = styled.input.attrs({ type: "email" })`
  border-radius: 8px;
  background-color: #fff;
  font-size: 1em;
  color: var(--color-great-dane);
  padding: 1em ${em(18)};
  border: 0;
`
