import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

const StyledInputFieldWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  justify-content: center;
`

const StyledInputField = styled.input`
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0px 0px 0px 16px;
  height: 40px;
  text-align: left;
  color: rgb(0, 0, 0);
  width: 80%;
  border: none;
`

const StyledEmailFormButton = styled.button`
  padding: 11px 10px;
  background: rgb(255, 255, 255);
  border-radius: 2px;
  border-style: none;
  border-color: rgb(0, 0, 0);
  border-width: 0px;
  color: rgb(61, 135, 203);
  font-weight: 700;
  cursor: pointer;
`

const StyledThankYouMessage = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  > p:first-child {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
  }

  > p:last-child {
    line-height: 1;
  }
`

function KlaviyoForm({ listId }) {
  const [email, setEmail] = useState()
  const [submitted, setSubmitted] = useState(false)

  async function handleFormSubmit() {
    const url = `${process.env.GATSBY_APP_STOREFRONT_GATEWAY_API}/lists/${listId}?key=${process.env.GATSBY_APP_STOREFRONT_GATEWAY_API_TOKEN}`
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profiles: [{ email }],
      }),
    }

    const response = await fetch(url, options)

    const result = await response.json()

    if (result) {
      setSubmitted(true)
    }
  }
  return (
    <>
      {submitted ? (
        <StyledThankYouMessage>
          <p>Thanks for subscribing!</p>
          <p>Check your email for a confirmation message.</p>
        </StyledThankYouMessage>
      ) : (
        <StyledInputFieldWrapper>
          <StyledInputField
            type="email"
            placeholder="Email Address"
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
          <StyledEmailFormButton onClick={() => handleFormSubmit()}>
            Sign Up
          </StyledEmailFormButton>
        </StyledInputFieldWrapper>
      )}
    </>
  )
}

KlaviyoForm.defaultProps = {
  listId: "L9Hwqv",
}

KlaviyoForm.propTypes = {
  listId: PropTypes.string,
}

export default React.memo(KlaviyoForm)
