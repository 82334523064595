import React from "react"
import { useToggle } from "../../hooks/useToggle"
import Icon from "../Icon/Icon"
import {
  StyledAccordion,
  StyledAccordionContent,
  StyledAccordionHeader,
  StyledAccordionHeading,
  StyledAccordionToggle,
} from "./StyledAccordion"

function Accordion({ titleTagName, title, textColor, children, uuid }) {
  const [open, setOpen] = useToggle()

  return (
    <StyledAccordion textColor={textColor} isOpen={open}>
      <StyledAccordionHeader textColor={textColor} isOpen={open}>
        <StyledAccordionToggle
          aria-controls={`accordion-content-${uuid}`}
          aria-expanded={open}
          onClick={() => setOpen(!open)}
        >
          <StyledAccordionHeading
            id={`accordion-title-${uuid}`}
            as={titleTagName}
          >
            {title}
          </StyledAccordionHeading>
          <Icon
            name={open ? "subtraction" : "addition"}
            width={16}
            height={16}
            color={textColor}
          />
        </StyledAccordionToggle>
      </StyledAccordionHeader>
      <StyledAccordionContent
        aria-labelledby={`accordion-title-${uuid}`}
        id={`accordion-content-${uuid}`}
        textColor={textColor}
        isOpen={open}
      >
        {children}
      </StyledAccordionContent>
    </StyledAccordion>
  )
}

export default Accordion
