import styled from "styled-components"

export const StyledBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 2;
`
