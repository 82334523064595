import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { useImageByDatabaseId } from "../../hooks/useImageByDatabaseId"

const BackgroundImage = styled(GatsbyImage)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
`

const StyledMobileBackground = styled(BackgroundImage)`
  @media only screen and (min-width: 64em) {
    display: none;
  }
`

const StyledDesktopBackground = styled(BackgroundImage)``

const StyledBrochureItem = styled.div`
  position: relative;
  display: grid;

  &::before {
    content: "";
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    ${({ backgroundColor }) =>
      backgroundColor && `background-color: ${backgroundColor};`}
    ${({ dimRatio }) => !!dimRatio && `opacity: ${dimRatio * 0.01};`}
  }

  @media only screen and (max-width: 1023px) {
    &::before {
      ${({ mobileBackgroundColor }) =>
        mobileBackgroundColor && `background-color: ${mobileBackgroundColor};`}
      ${({ mobileDimRatio }) =>
        !!mobileDimRatio && `opacity: ${mobileDimRatio * 0.01};`}
    }

    ${StyledDesktopBackground} {
      ${({ mobileMediaId }) => !!mobileMediaId && `display: none`};
    }
  }
`

const StyledBrochureItemInner = styled.div`
  display: grid;
  margin: 0;
  min-height: 400px;
  padding: 40px 4em;
  position: relative;
  place-content: center left;
  width: 100%;
  z-index: 1;
`

function getMediaPosition({ x = "0.5", y = "0.5" } = {}) {
  return `${Math.round(x * 100)}% ${Math.round(y * 100)}%`
}

function BrochureItem({ children, ...attributes }) {
  const {
    backgroundColor,
    dimRatio,
    mediaId,
    mobileMediaId,
    mediaPosition,
    mobileDimRatio,
    mobileMediaPosition,
    mobileBackgroundColor,
  } = attributes

  const imageByDatabaseId = useImageByDatabaseId(mediaId)
  const image = getImage(imageByDatabaseId)

  const mobileImageByDatabaseId = useImageByDatabaseId(mobileMediaId)
  const mobileImage = getImage(mobileImageByDatabaseId)

  return (
    <StyledBrochureItem
      dimRatio={dimRatio}
      mobileDimRatio={mobileDimRatio}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
      mediaId={mediaId}
      mobileMediaId={mobileMediaId}
    >
      {dimRatio !== 100 && image && (
        <StyledDesktopBackground
          image={image}
          alt=""
          objectPosition={getMediaPosition(mediaPosition)}
          backgroundColor="transparent"
        />
      )}
      {mobileDimRatio !== 100 && mobileImage && (
        <StyledMobileBackground
          image={mobileImage}
          alt=""
          objectPosition={getMediaPosition(mobileMediaPosition)}
          backgroundColor="transparent"
        />
      )}
      <StyledBrochureItemInner>{children}</StyledBrochureItemInner>
    </StyledBrochureItem>
  )
}

export default BrochureItem
