import React from "react"
import styled from "styled-components"

const StyledColumn = styled.div`
  .inline-gatsby-image-wrapper {
    > picture {
      > img {
        object-fit: contain !important;
      }
    }
  }
`

function Column({ children }) {
  return <StyledColumn>{children}</StyledColumn>
}

export default Column
