import styled from "styled-components"

const setGridStyles = () => `
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto;
`

export const StyledButton = styled.button`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : `var(--color-irish-wolfhound)`};
  color: ${({ textColor }) => (textColor ? textColor : `#fff`)};
  border: 2px solid
    ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : `var(--color-irish-wolfhound)`};
  cursor: pointer;
  display: inline-block;
  padding: 1rem 1.5rem;
  text-decoration: none;
  font-weight: var(--font-bold);
  text-align: center;
  &:focus {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-great-dane) 0 0 0 4px;
  }
  &:hover {
    background-color: var(--color-great-dane);
  }
`

export const StyledQuantityField = styled.label`
  font-weight: var(--font-bold);
  border-radius: 6px;
  border: 2px solid var(--color-gray);
  background: #fff;
  margin-right: 2em;
  display: flex;
`

export const StyledQuantityFieldControl = styled.button`
  color: var(--color-great-dane);
  border: none;
  background: none;
  outline: none;
  padding: 0.25em 0.75em;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-great-dane) 0 0 0 4px;
  }
`

export const StyledQuantityFieldInput = styled.input`
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 1em;
  margin: 0 0.25em;
  max-width: 100%;
  padding: 1rem 0;
  text-align: center;
  width: 40px;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const StyledButtonMessage = styled.span`
  display: flex;
  align-items: center;
  gap: 0.75em;
  justify-content: center;
`

export const StyledButtonWrap = styled.div`
  ${({ showQuantity }) => showQuantity && setGridStyles()}

  ${({ alignment, showQuantity }) =>
    alignment && alignment !== "left" && !showQuantity && "display: flex;"};

  ${({ alignment, showQuantity }) =>
    !showQuantity && alignment === "right" && "justify-content: flex-end;"};

  ${({ alignment, showQuantity }) =>
    !showQuantity && alignment === "center" && "justify-content: center;"};

  ${StyledButton} {
    ${({ withLargeRadius }) =>
      withLargeRadius ? "border-radius: 2em" : "border-radius: 6px"};

    ${({ size }) =>
      size === "small" &&
      "font-size: 12px; width: 116px; padding: 7px 10px; @media only screen and (min-width: 768px) {font-size: 13px; width: 131px; padding: 7px 10px;}"};

    background-color: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : `var(--color-irish-wolfhound)`};
    border: 2px solid
      ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : `var(--color-irish-wolfhound)`};
    color: ${({ textColor }) => (textColor ? textColor : `#fff`)};
  }

  ${StyledQuantityField} {
    ${({ size }) => size === "small" && "margin-right: 1em;"};
  }

  ${StyledQuantityFieldInput} {
    ${({ size }) =>
      size === "small" &&
      "font-size: 13px; padding: 5px 0px; width: 15px;  @media only screen and (min-width: 768px) {font-size: 15px; padding: 7px 0px; width: 20px;}"};
  }
`
