import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

export const StyledProductsContainer = styled.div`
  @media only screen and (min-width: 64em) {
    background-color: #f2f7fc;
    width: 746px;
    min-height: 304px !important;
    padding: 20px;
    line-height: 1.6;

    > div:first-of-type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }
`

export const StyledViewAllAnchor = styled(Link)`
  display: none;
  @media only screen and (min-width: 64em) {
    font-weight: 600;
    color: var(--color-irish-wolfhound);
    display: flex;
    align-items: center;
    gap: 0.6em;
    cursor: pointer;
    line-height: 1.6;
    margin-bottom: 0.5em;
  }
`

export const StyledHeader = styled.h4`
  font-size: 16px;
  @media only screen and (min-width: 64em) {
    font-weight: 800;
    font-size: 24px;
  }
`

export const StyledProductWrap = styled.div`
  display: flex;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (min-width: 64em) {
    flex-wrap: wrap;
    overflow: none;
  }
`

export const StyledPopularProduct = styled.div`
  background-color: #f2f7fc;
  display: flex;
  width: 310px;
  margin: 0.5em 0.5em 0.5em 0;
  cursor: pointer;
  line-height: 1.6;
  border-radius: 6px;

  @media only screen and (min-width: 64em) {
    margin: 1em 1.5em 1em 1em;
  }

  > div:first-of-type {
    @media only screen and (min-width: 64em) {
      margin: 0 10px 0 0;
      width: 75px;
      height: 75px;
    }
    margin: 10px;
    width: 60px;
    height: 60px;
  }

  > div:nth-child(2) {
    margin-top: 10px;

    @media only screen and (min-width: 64em) {
      margin-top: 0;
    }
    > h6 {
      color: var(--color-great-dane);
      font-size: 14px;
      margin: 0;

      @media only screen and (min-width: 64em) {
        font-size: 16px;
      }
    }

    > p {
      font-size: 14px;
      margin: 0;
      color: var(--color-great-dane);

      @media only screen and (min-width: 64em) {
        font-size: 16px;
      }
    }
  }
`

function Product({ product, urlObject }) {
  const { id, image, shopifyId, hasOnlyDefaultVariant, selectedOptions } =
    product
  const {
    popularShopifyProductPage: { uri = "/" },
    title,
    subtext,
  } = urlObject[shopifyId]

  const optionsToState = !hasOnlyDefaultVariant
    ? selectedOptions.reduce((acc, option) => {
        if (!acc[option.name]) {
          acc[option.name] = option.value
        }

        return acc
      }, {})
    : null

  return (
    <Link
      to={uri}
      style={{ textDecoration: "none" }}
      state={{
        ...(optionsToState && { options: optionsToState }),
      }}
    >
      <StyledPopularProduct key={id}>
        <div>
          <GatsbyImage
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={image.altText ?? `${title} - ${subtext}`}
          />
        </div>
        <div>
          <h6>{title}</h6>
          <p>{subtext}</p>
        </div>
      </StyledPopularProduct>
    </Link>
  )
}

function PopularProducts({ menu }) {
  const graphqlResult = useStaticQuery(graphql`
    {
      allShopifyProduct(filter: { tags: { eq: "Popular" } }) {
        edges {
          node {
            id
            variants {
              id
              title
              displayName
              shopifyId
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 60, height: 60, placeholder: BLURRED)
                  }
                }
                altText
              }
              selectedOptions {
                name
                value
              }
            }
            hasOnlyDefaultVariant
            title
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 60, height: 60, placeholder: BLURRED)
                }
              }
            }
            shopifyId
          }
        }
      }
    }
  `)

  const products = graphqlResult?.allShopifyProduct.edges

  // This object consists of either Product GIDs or Product Variant GIDs that we want to display in our popular product menus
  const urlObject = menu.reduce((acc, curr) => {
    acc[curr.popularShopifyProduct] = curr
    return acc
  }, {})

  // This list consist of ALL products tagged with "Popular" but we only want the ones that are added to a Popular Products menu
  // so we filter out the ones that don't exist by looking up their associated Page object by Shopify GID
  const variants = products
    .reduce((acc, curr) => {
      const product = curr.node

      if (product.hasOnlyDefaultVariant) {
        return acc.concat({
          ...product,
          image: product.featuredImage,
        })
      }
      return acc.concat(product.variants)
    }, [])
    .filter(variant => urlObject[variant.shopifyId])

  return (
    <StyledProductsContainer>
      <div>
        <StyledHeader>Popular Products</StyledHeader>
        <StyledViewAllAnchor to="/shop" style={{ textDecoration: "none" }}>
          View All{" "}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
              fill="#004986"
            />
          </svg>
        </StyledViewAllAnchor>
      </div>
      <StyledProductWrap>
        {variants.map(variant => {
          return (
            <Product key={variant.id} product={variant} urlObject={urlObject} />
          )
        })}
      </StyledProductWrap>
    </StyledProductsContainer>
  )
}

export default PopularProducts
