import { em } from "polished"
import React from "react"
import styled from "styled-components"
import { formatPrice } from "../../utils/money"
import { ScreenReaderText } from "../ScreenReaderText"

const StyledProductHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${em(32)};

  h2 {
    font-size: ${em(32)};
    margin: 0;
  }

  p {
    color: var(--color-great-dane);
    font-weight: var(--font-black);
    font-size: ${em(32)};
    margin: 0;
  }

  @media only screen and (min-width: 48em) {
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
`

function ProductHeader({ displayName, price }) {
  return (
    <StyledProductHeader>
      <h2>{displayName}</h2>
      <p>
        <ScreenReaderText>Regular price</ScreenReaderText>{" "}
        <span>{formatPrice(price)}</span>
      </p>
    </StyledProductHeader>
  )
}

export default ProductHeader
