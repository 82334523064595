import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StyledButton } from "./StyledButton"

export const StyledCheckoutButton = styled(StyledButton).attrs({
  as: Link,
})`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-green);
  border: none;
  border-radius: 6px;
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;

  &:hover {
    background-color: var(--color-green);
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    width: 390px;
  }
`

function ButtonUpsellCheckout() {
  return (
    <StyledCheckoutButton className="upsell-checkout-button" to="/cart">
      Checkout
    </StyledCheckoutButton>
  )
}

export default ButtonUpsellCheckout
