import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { PURCHASE_TYPE_SUBSCRIPTION } from "../../constants"
import { useStore } from "../../hooks/useStore"
import { parseGid } from "../../utils/parseGid"
import ButtonUpsellCheckout from "../Buttons/ButtonUpsellCheckout"
import Icon from "../Icon/Icon"
import Portal from "../Portal/Portal"
import UpsellModalItem from "./UpsellModalItem"

const StyledUpsellModal = styled.aside`
  position: absolute;
  max-height: 100%;
  background-color: #fff;
  z-index: 1;
  right: 0;
  top: ${({ offsetTop }) => `${offsetTop}px`};
  bottom: 0; // Corrects for scroll-padding-bottom clipping on mobile
  overflow-y: auto;
  width: 100%;
  padding: 0px 24px;
  scroll-padding-bottom: ${({ offsetTop }) => `${offsetTop}px`};

  @media only screen and (min-width: 64em) {
    max-width: 485px;
    max-height: 685px;
  }

  @media only screen and (min-width: 48em) {
    padding: 0px 40px;
  }
`

const StyledAddedItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px 0px;

  > div {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 390px;
  }
`

const StyledText = styled.p`
  color: var(--color-irish-wolfhound);
  font-size: 18px;
  font-weight: bold;
`

const StyledCloseModalButton = styled.button`
  color: var(--color-irish-wolfhound);
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  &:focus {
    outline: none;
    box-shadow: #fff 0 0 0 2px, var(--color-great-dane) 0 0 0 4px;
  }
  &:hover {
    color: var(--color-great-dane);
  }
`

const StyledModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 1400px;
  margin-inline: auto;
  z-index: 1;
`

function UpsellModal({ on, toggle, variant, purchaseType }) {
  const graphqlResult = useStaticQuery(graphql`
    {
      allShopifyCollection(filter: { title: { eq: "Upsells" } }) {
        nodes {
          products {
            hasOnlyDefaultVariant
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 68, height: 68, placeholder: BLURRED)
                }
              }
            }
            variants {
              metafields {
                key
                value
              }
              product {
                shopifyId
                metafields {
                  key
                  value
                }
              }
              price
              displayName
              id
              shopifyId
              storefrontId
              sku
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 68, height: 68, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [{ products }] = graphqlResult?.allShopifyCollection?.nodes
  const [offsetTop, setOffsetTop] = useState()
  const { checkout, getProductByVariantMatch, getVariant } = useStore()

  useEffect(() => {
    const offsetTop = document.getElementById("header").clientHeight
    setOffsetTop(offsetTop)

    if (on) {
      document.querySelector("body").style.overflow = "hidden"
    }

    return () => {
      document.querySelector("body").style = ""
    }
  }, [on])

  const variants = products.reduce((acc, curr) => {
    if (curr.hasOnlyDefaultVariant) {
      const [product] = curr.variants
      return acc.concat({ ...product, image: curr.featuredImage })
    }

    return acc.concat(curr.variants)
  }, [])

  useEffect(() => {
    if (checkout?.currencyCode && variants?.length > 0) {
      const datalayerImpressions = variants.map((variant, idx) => {
        const lineItemProduct = getProductByVariantMatch({
          storefrontId: variant.storefrontId,
        })

        const lineItemVariant = getVariant(
          { storefrontId: variant.storefrontId },
          lineItemProduct.variants
        )

        return {
          position: idx + 1,
          name: lineItemProduct.title,
          id: (lineItemVariant && lineItemVariant.sku) || "",
          product_id: parseGid(lineItemProduct.shopifyId) || "",
          variant_id: parseGid(lineItemVariant.shopifyId) || "",
          image: lineItemProduct?.featuredImage?.originalSrc || "",
          price: lineItemVariant.price,
          brand: lineItemProduct.vendor,
          variant: lineItemProduct.hasOnlyDefaultVariant
            ? lineItemProduct.title
            : lineItemVariant.displayName,
          category: lineItemProduct.productType,
        }
      })

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "dl_view_item_list",
        event_id: uuidv4(), // unique uuid for FB conversion API
        ecommerce: {
          currencyCode: checkout.currencyCode,
          impressions: datalayerImpressions,
        },
      })
    }
  }, [])

  const isSubscriptionUpsell = purchaseType === PURCHASE_TYPE_SUBSCRIPTION

  return (
    <Portal>
      <StyledModalWrap>
        <StyledUpsellModal offsetTop={offsetTop} className="upsell-modal">
          <StyledAddedItemInfo>
            <div>
              <GatsbyImage
                image={variant.image.localFile.childImageSharp.gatsbyImageData}
                alt={variant.altText ?? variant.title}
              />
              <svg
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.759 1.97729C6.95266 1.97729 2.24023 6.40639 2.24023 11.8637C2.24023 17.3209 6.95266 21.75 12.759 21.75C18.5654 21.75 23.2779 17.3209 23.2779 11.8637C23.2779 6.40639 18.5654 1.97729 12.759 1.97729ZM10.6553 16.8068L5.39588 11.8637L6.87903 10.4697L10.6553 14.009L18.6391 6.50525L20.1222 7.90911L10.6553 16.8068Z"
                  fill="#54BB53"
                />
              </svg>
              <StyledText>Added to Cart</StyledText>
            </div>
            <StyledCloseModalButton onClick={toggle}>
              <Icon name="close" />
            </StyledCloseModalButton>
          </StyledAddedItemInfo>
          <ButtonUpsellCheckout />
          {variants.map(variant => (
            <UpsellModalItem
              key={variant.id}
              isSubscriptionUpsell={isSubscriptionUpsell}
              purchaseType={purchaseType}
              {...variant}
            />
          ))}
        </StyledUpsellModal>
      </StyledModalWrap>
    </Portal>
  )
}

export default UpsellModal
