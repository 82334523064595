import { getTontineVariant, useTontineContext } from "@tontine/tontine-react"
import React, { useState } from "react"
import { PURCHASE_TYPE_ONETIME } from "../../constants"
import { useStore } from "../../hooks/useStore"
import { getProductByStorefrontId } from "../../utils/getProductByStorefrontId"
import { getProductOptions } from "../../utils/getProductOptions"
import { parseGid } from "../../utils/parseGid"
import { variantForOptions } from "../../utils/variantsForOptions"
import ButtonAddToCart from "../Buttons/ButtonAddToCart"
import ClothingHeader from "./ClothingHeader"
import ProductOptions from "./ProductOptions"
import { StyledBuyBox } from "./StyledBuyBox"

function BuyBoxBeanie({
  buttonText,
  children,
  showQuantity,
  discountCode,
  variant: DEFAULT_VARIANT,
}) {
  const { products } = useStore()
  // gracefully handle legacy gutenberg redux api
  let product = getProductByStorefrontId(
    DEFAULT_VARIANT?.productId || DEFAULT_VARIANT?.product?.storefrontId,
    products
  )

  const tontinecontext = useTontineContext()
  const tontineEnabled = (product?.metafields || []).find(
    meta =>
      meta.namespace === "italicapp" &&
      meta.key === "enabled" &&
      meta.value === "true"
  )

  if (tontineEnabled && !tontinecontext.loading) {
    const tontineFields = product.metafields
      .filter(field => field.namespace === "italicapp")
      .reduce((acc, m) => {
        acc[m.key] = m.value
        return acc
      }, {})
    const tontineTestSplit = tontineFields.trafficSplit
    const tontineTestType = tontineFields.multiarmed === "true" ? "mab" : "std"
    const tontineVariation = tontineFields.variation
    const variation = getTontineVariant(
      tontinecontext.variation,
      tontineTestType,
      parseInt(tontineTestSplit)
    )
    if (variation !== tontineVariation) {
      const tontineId = tontineFields[`${variation}productid`]
      const encodedTontineId = window.btoa(`gid://shopify/Product/${tontineId}`)
      product = getProductByStorefrontId(encodedTontineId, products)
    }
    // Trigger analytics event to track price variant views
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "tontine-test-view",
      event_category: "tontine test view",
      event_label: variation,
    })
  }

  const availableOptions = getProductOptions(product)
  const [options, setOptions] = useState({})

  const onetimeVariant = variantForOptions(product, options)
  const [variant, setVariant] = useState(onetimeVariant)

  return (
    <>
      <StyledBuyBox>
        <ClothingHeader displayName={product.title} price={variant.price} />
        <ProductOptions
          options={options}
          availableOptions={availableOptions}
          handleChangeOption={e => {
            const { name, value } = e.target
            const selectedOptions = { ...options, [name]: value }

            setOptions(selectedOptions)
            setVariant(variantForOptions(product, selectedOptions))
          }}
        />
        {children}
        <ButtonAddToCart
          showQuantity={showQuantity}
          text={buttonText}
          afterAddedText="✔︎ Added"
          variant={variant}
          product={product}
          purchaseType={PURCHASE_TYPE_ONETIME}
          discountCode={discountCode}
          __dataLayer={{
            ProductTitle: product.title,
            ProductCategory: product.productType,
            ProductID: parseGid(product.shopifyId),
            ProductGID: product.shopifyId,
            PurchaseType: PURCHASE_TYPE_ONETIME,
            VariantName: variant.displayName,
            VariantID: parseGid(variant.shopifyId),
            VariantGID: variant.shopifyId,
            VariantSKU: variant.sku,
            VariantValue: variant.price,

            product_ids: [parseGid(product.shopifyId)],
            product_type: "product_group",
            product_category: product.productType,
            product_name: variant.displayName,
            line_item_value: variant.price,
          }}
        />
      </StyledBuyBox>
    </>
  )
}

export default BuyBoxBeanie
