import React from "react"
import styled from "styled-components"

const StyledListItem = styled.li`
  display: flex;

  > svg:first-child {
    margin-right: 0.5em;
    flex-shrink: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 0.5em;
  }
`

function IconListItem({ children }) {
  return <StyledListItem>{children}</StyledListItem>
}

export default IconListItem
