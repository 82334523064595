import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

export const StyledSVG = styled.svg`
  display: block;
`

function Icon({ name, color, size, ...rest }) {
  switch (name) {
    case "hamburger":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 12"
          {...rest}
        >
          <path
            d="m0 12h18v-2h-18zm0-5h18v-2h-18zm0-7v2h18v-2z"
            fill="currentColor"
          />
        </StyledSVG>
      )
    case "addition":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 10"
          {...rest}
        >
          <path
            d="M0 5h10M5 0v10"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
          />
        </StyledSVG>
      )

    case "radio":
      return (
        <StyledSVG
          fill="currentColor"
          className="svg"
          viewBox="0 0 20 20"
          {...rest}
        >
          <circle
            cx="10"
            cy="10"
            r="9"
            fill="#fff"
            stroke="#d8d8d8"
            strokeWidth="2"
          ></circle>
          <circle cx="10" cy="10" r="5"></circle>
        </StyledSVG>
      )
    case "spinner":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 50 50"
          {...rest}
        >
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
            stroke={color}
          ></circle>
        </StyledSVG>
      )

    case "subtraction":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 10"
          {...rest}
        >
          <path stroke="currentColor" strokeWidth="1.5" d="M.5 5.5h10" />
        </StyledSVG>
      )

    case "discount":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          {...rest}
        >
          <path
            d="m12.5025 6.9c1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81v-2.19h-3v2.16c-.39.08-.75.21-1.1.36l1.51 1.51c.32-.08.69-.13 1.09-.13zm-7.03-2.98-1.41 1.41 3.44 3.44c0 2.08 1.56 3.22 3.91 3.91l3.51 3.51c-.34.49-1.05.91-2.42.91-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83v2.17h3v-2.15c.96-.18 1.83-.55 2.46-1.12l2.22 2.22 1.41-1.41z"
            fill="currentColor"
          />
        </StyledSVG>
      )

    case "truck":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          {...rest}
        >
          <path
            d="m20.002 8h-3v-4h-14.00005c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6.00005c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5zm-.5 1.5 1.96 2.5h-4.46v-2.5zm-13.50005 8.5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1h-.78v-9h12.00005v9zm9.78005 3c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
            fill="currentColor"
          />
        </StyledSVG>
      )

    case "published-with-changes":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          {...rest}
        >
          <path
            d="m17.662 9.53005-7.07 7.06995-4.24005-4.24 1.41-1.41 2.83005 2.83 5.66-5.65995zm-13.66005 2.46995c0-2.32995 1.02-4.41995 2.62-5.87995l2.38 2.38v-6h-6l2.2 2.2c-1.96 1.82-3.2 4.41-3.2 7.29995 0 5.19 3.95 9.4501 9.00005 9.9501v-2.0201c-3.94005-.4899-7.00005-3.86-7.00005-7.93zm18.00005 0c0-5.18995-3.95-9.44995-9-9.94995v2.02c3.94.49 7 3.86 7 7.92995 0 2.33-1.02 4.42-2.62 5.88l-2.38-2.38v6h6l-2.2-2.2c1.96-1.82 3.2-4.41 3.2-7.3z"
            fill="currentColor"
          />
        </StyledSVG>
      )

    case "close":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
          {...rest}
        >
          <path
            d="M28 2.81988L25.18 -0.00012207L14 11.1799L2.82 -0.00012207L0 2.81988L11.18 13.9999L0 25.1799L2.82 27.9999L14 16.8199L25.18 27.9999L28 25.1799L16.82 13.9999L28 2.81988Z"
            fill="currentColor"
          />
        </StyledSVG>
      )

    case "cart":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          {...rest}
        >
          <path
            fill="#fff"
            d="M7.167 19c-1.283 0-2.322 1.05-2.322 2.333s1.039 2.334 2.322 2.334c1.283 0 2.333-1.05 2.333-2.333S8.45 19 7.167 19zM.167.333v2.334H2.5l4.2 8.855-1.575 2.858a2.255 2.255 0 00-.291 1.12 2.34 2.34 0 002.333 2.333h14V15.5H7.657a.289.289 0 01-.292-.291l.035-.14 1.05-1.902h8.692c.875 0 1.645-.478 2.042-1.202l4.176-7.572a1.17 1.17 0 00-1.027-1.727H5.08L3.982.334zM18.834 19c-1.284 0-2.322 1.05-2.322 2.333s1.038 2.334 2.322 2.334c1.283 0 2.333-1.05 2.333-2.333S20.117 19 18.834 19z"
          ></path>
        </StyledSVG>
      )

    case "info":
      return (
        <StyledSVG
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          {...rest}
        >
          <path
            fill="currentColor"
            d="M10 1.667C5.4 1.667 1.665 5.4 1.665 10s3.733 8.333 8.333 8.333S18.333 14.6 18.333 10s-3.734-8.333-8.334-8.333zm0 12.5a.836.836 0 01-.834-.834V10c0-.458.375-.833.833-.833.459 0 .834.375.834.833v3.333a.836.836 0 01-.834.834zm.833-6.667H9.166V5.833h1.667z"
          ></path>
        </StyledSVG>
      )

    default:
      return null
  }
}

Icon.defaultProps = {
  color: "#182849",
  height: 28,
  width: 28,
}

Icon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
}

export default Icon
