import React from "react"
import styled from "styled-components"
import PrimaryNavigation from "./PrimaryNavigation"
import Snackbar from "./Snackbar"

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--color-irish-wolfhound);
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0 1rem;
  height: 73px;
  @media only screen and (min-width: 64em) {
    padding: 0 2rem;
    height: 80px;
  }
`

function Header() {
  return (
    <>
      <Snackbar />
      <StyledHeader id="header">
        <PrimaryNavigation />
      </StyledHeader>
    </>
  )
}

export default Header
