import React from "react"
import styled from "styled-components"

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 1em;
`

function IconList({ children }) {
  return <StyledList>{children}</StyledList>
}

export default IconList
