import React, { useCallback, useEffect, useState } from "react"
import NavigationSubmenu from "./NavigationSubmenu"
import NavigationSubmenuToggle from "./NavigationSubmenuToggle"
import { StyledMenuItem } from "./StyledNavigation"

function NavigationMenuItem({
  item,
  isDesktop,
  hasPopularProductsMenu,
  popularProductsMenu,
}) {
  const hasChildren = item.children.length > 0

  const [isAriaHidden, setAriaHidden] = useState(true)
  const [isAriaExpanded, setAriaExpanded] = useState(false)

  useEffect(() => {
    if (!isDesktop && !item.cssClasses.includes("m-is-dropdown")) {
      setAriaHidden(false)
      setAriaExpanded(true)
    } else {
      setAriaHidden(true)
      setAriaExpanded(false)
    }
  }, [isDesktop, item.cssClasses])

  const handleMenuVisible = useCallback(
    e => {
      if (isDesktop) {
        setAriaHidden(false)
        setAriaExpanded(true)
      }
    },
    [isDesktop]
  )

  const handleMenuHidden = useCallback(
    e => {
      if (isDesktop) {
        setAriaHidden(true)
        setAriaExpanded(false)
      }
    },
    [isDesktop]
  )

  return (
    <StyledMenuItem
      className={item.cssClasses.join(" ")}
      isAriaExpanded={isAriaExpanded}
      isAriaHidden={isAriaHidden}
      onFocus={hasChildren ? handleMenuVisible : undefined}
      onBlur={hasChildren ? handleMenuHidden : undefined}
      onMouseEnter={hasChildren ? handleMenuVisible : undefined}
      onMouseLeave={hasChildren ? handleMenuHidden : undefined}
    >
      <NavigationSubmenuToggle
        isAriaExpanded={isAriaExpanded}
        isAriaHidden={isAriaHidden}
        handleToggle={() => {
          if (!isDesktop) {
            setAriaHidden(!isAriaHidden)
            setAriaExpanded(!isAriaExpanded)
          }
        }}
        {...item}
      />
      <NavigationSubmenu
        isAriaHidden={isAriaHidden}
        isAriaExpanded={isAriaExpanded}
        hasChildren={hasChildren}
        hasPopularProductsMenu={hasPopularProductsMenu}
        popularProductsMenu={popularProductsMenu}
        {...item}
      />
    </StyledMenuItem>
  )
}

export default NavigationMenuItem
