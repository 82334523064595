import { useContext } from "react"
import { CookieContext } from "../providers/CookieProvider"

export function useCookies() {
  const context = useContext(CookieContext)
  if (context === undefined) {
    throw new Error("useCookies must be used within a CookieContext provider")
  }

  return context
}
