import React from "react"
import styled from "styled-components"

export const StyledContentBlockContent = styled.div``

function ContentBlockContent(props) {
  return <StyledContentBlockContent {...props} />
}

export default ContentBlockContent
