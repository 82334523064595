import PropTypes from "prop-types"
import React, { useEffect } from "react"

function WidgetLooxWaterfall({ aggregate, limit, variant }) {
  useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.src = "//loox.io/widget/loox.js?shop=wildearthpets.myshopify.com"

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div
      id="looxReviews"
      data-fetch={variant?.product_id && !aggregate ? "true" : undefined}
      data-product-id={
        variant?.product_id && !aggregate ? variant.product_id : undefined
      }
      data-loox-aggregate={aggregate ? "true" : undefined}
      data-limit={limit < 0 ? undefined : limit}
    />
  )
}

WidgetLooxWaterfall.defaultProps = {
  limit: 10,
  aggregate: false,
}

WidgetLooxWaterfall.propTypes = {
  limit: PropTypes.number,
  aggregate: PropTypes.bool,
}

export default WidgetLooxWaterfall
