import { graphql, useStaticQuery } from "gatsby"

/**
 * Retrieve all media assets from our build and filter through them for the requested asset
 * matching the databaseId.
 *
 * We aren't able to use GraphQL Variables outside of the Gatsby Page Context (i.e. in a component)
 * so our work around is to request all image assets and their database IDs and pluck out the one we want by
 * filtering the list.
 *
 * @param {string} databaseId WordPress database ID for the asset
 * @returns {Object} object containing childImageSharp data
 */
export function useImageByDatabaseId(databaseId) {
  const graphqlResult = useStaticQuery(graphql`
    {
      allWpMediaItem {
        nodes {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          databaseId
        }
      }
    }
  `)

  const images = graphqlResult?.allWpMediaItem?.nodes
  const [image] = images.filter(image => image.databaseId === databaseId)
  return image?.localFile
}
