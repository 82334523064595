import { useEffect, useState } from "react"

export function useVisibility(ref, rootMargin = 0) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const domNode = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin:
          typeof rootMargin === "number" ? `${rootMargin}px` : rootMargin,
      }
    )

    if (domNode) {
      observer.observe(domNode)
    }

    return () => {
      observer.unobserve(domNode)
    }
  }, [])

  return isIntersecting
}
