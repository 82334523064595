import { PURCHASE_TYPE_ONETIME, PURCHASE_TYPE_SUBSCRIPTION } from "../constants"
import { getCustomAttributes } from "./getCustomAttributes"
import { getAttributeByKey } from "./getLineItemAttributes"
import { parseGid } from "./parseGid"

/**
 * Determines which type of product variant to add to cart using a set of stateful properties passed down from
 * parent components such as UpsellModal, BuyBoxFood, etc.,
 *
 * Requires the OTP product variant regardless of purchase type.
 *
 * @param {Object} otpProduct GraphQL Product for OTP item (i.e. the original, non auto-renew product)
 * @property {string} shopifyId decoded base64 GID
 * @param {Object} otpVariant GraphQL Product Variant for OTP item
 * @property {string} shopifyId decoded base64 GID
 * @property {string} storefrontId encoded base64 product variant GID
 * @property {Object[]} metafields list of product variant metafields configured within Shopify
 * @param {number} quantity number of associated item to add
 * @param {string} [purchaseType="subscription"] the type of purchase that user has added, either "onetime" or "subscription"
 * @param {string} [interval=""] friendly delivery interval, i.e. "2 weeks"
 * @param {Object} [discountCode={}] ReCharge discount object
 * @param {Object[]} [customAttributes=[]] list of objects containing a "key" and "value" field
 * @returns {Object} Shopify SDK line item to add
 */
export function getAttributedVariant(
  { shopifyId: productShopifyId },
  { metafields, storefrontId, price, shopifyId: variantShopifyId },
  quantity,
  purchaseType = PURCHASE_TYPE_SUBSCRIPTION,
  interval = "",
  discountCode = {},
  customAttributes = []
) {
  const getAttributeValue = getAttributeByKey(metafields)
  const defaultAttributes = [
    { key: "original_product_id", value: parseGid(productShopifyId) },
    { key: "original_variant_id", value: parseGid(variantShopifyId) },
  ]
  const hasDiscount = discountCode?.code

  if (purchaseType === PURCHASE_TYPE_ONETIME) {
    const discount_variant_id = getAttributeValue("discount_variant_id")
    const discount_variant_price = getAttributeValue("discount_variant_price")
    return {
      variantId: storefrontId,
      quantity,
      customAttributes: [
        ...getCustomAttributes(customAttributes),
        ...defaultAttributes,
        ...(discount_variant_id
          ? [
              { key: "discount_variant_id", value: discount_variant_id },
              { key: "discount_variant_price", value: discount_variant_price },
            ]
          : []),
        ...(hasDiscount
          ? [
              { key: "subscription_discount_code", value: discountCode.code },
              {
                key: "subscription_discount_value",
                value: String(discountCode.value),
              },
            ]
          : []),
      ],
    }
  }

  const discountVariantId = getAttributeValue("discount_variant_id")
  const variantId = window.btoa(
    `gid://shopify/ProductVariant/${discountVariantId}`
  )

  const frequency = parseInt(interval, 10)
  const unit = interval.replace(/[0-9]|s$/gi, "").trim()

  const subscriptionCustomAttributes = getCustomAttributes(
    customAttributes
  ).filter(attr => {
    return !["discount_variant_id", "discount_variant_price"].includes(attr.key)
  })

  return {
    variantId,
    quantity,
    customAttributes: [
      ...subscriptionCustomAttributes,
      ...defaultAttributes,
      { key: "original_price", value: String(price) },
      { key: "charge_interval_frequency", value: String(frequency) },
      { key: "shipping_interval", value: interval },
      { key: "shipping_interval_frequency", value: String(frequency) },
      { key: "shipping_interval_unit", value: unit },
      { key: "shipping_interval_unit_type", value: unit },
      ...(hasDiscount
        ? [
            { key: "subscription_discount_code", value: discountCode.code },
            {
              key: "subscription_discount_value",
              value: String(discountCode.value),
            },
          ]
        : []),
    ],
  }
}
