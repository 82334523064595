import { Link } from "gatsby"
import React from "react"
import { StyledAnchor } from "./StyledNavigation"

function getMenuItemLabel(item) {
  if (item.children.length) {
    return (
      <>
        <span>{item.label}</span>
        <svg
          aria-hidden="true"
          viewBox="0 0 7 4"
          xmlns="http://www.w3.org/2000/svg"
          width={12}
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M.82 0L3.5 2.481 6.18 0 7 .76 3.5 4 0 .76z"
          ></path>
        </svg>
      </>
    )
  }

  return item.label
}

function NavigationSubmenuToggle({
  url,
  target,
  linkRelationship,
  handleToggle,
  hasChildren,
  ...menuItem
}) {
  const isAbsolute = url => /^https?:\/\//i.test(url)

  return url !== "#" ? (
    <StyledAnchor
      as={isAbsolute(url) ? "a" : Link}
      to={isAbsolute(url) ? undefined : url}
      href={isAbsolute(url) ? url : undefined}
      target={target}
      rel={linkRelationship}
      aria-haspopup={hasChildren ? "true" : "false"}
    >
      {getMenuItemLabel(menuItem)}
    </StyledAnchor>
  ) : (
    <StyledAnchor
      as="button"
      aria-haspopup={hasChildren ? "true" : "false"}
      onClick={handleToggle}
    >
      {getMenuItemLabel(menuItem)}
    </StyledAnchor>
  )
}

export default NavigationSubmenuToggle
