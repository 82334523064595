import { graphql, Link, useStaticQuery } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import { columns } from "../utils/columns"
import FooterNavigation from "./FooterNavigation"
import Logo, { StyledLogoWrap } from "./Logo/Logo"
import { ScreenReaderText } from "./ScreenReaderText"

const StyledFooter = styled.footer`
  background-color: var(--color-great-dane);
  padding: 66px 24px 28px;

  @media only screen and (min-width: 48em) {
    padding: 66px 24px 28px;
  }
`

const StyledFooterWrapper = styled.div`
  max-width: ${columns(11)}px;
  margin: 0 auto;
`

const StyledFooterGrid = styled.div`
  .gatsby-image-wrapper {
    flex-shrink: 1;
  }

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    flex-basis: 200px;

    ${StyledLogoWrap} {
      flex-grow: 1;
      width: 100%;
      position: static;
      transform: none;
      display: initial;
      height: auto;
      margin: 0;
      > a {
        display: block;
        max-width: 200px;
      }
      @media only screen and (min-width: 64em) {
        margin: 0 0 1em 0;
      }

      svg {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: 48em) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
      padding: 0;
    }
  }

  @media only screen and (min-width: 64em) {
    > div:first-child {
      gap: 1rem;
    }
  }
`

const StyledIconList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    &:not(:last-child) {
      margin: 0 1rem 0.5rem 0;
    }
  }

  @media only screen and (min-width: 48em) {
    text-align: center;
  }
`

const StyledFooterMeta = styled.div`
  color: #fff;
  margin-top: 2rem;
  ${StyledIconList} {
    li {
      margin-bottom: 0;
      line-height: 1;
      vertical-align: middle;
    }
  }

  > p {
    font-size: 14px;
    font-weight: var(--font-bold);
  }

  @media only screen and (min-width: 48em) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
  }
`

const StyledFooterAdditionalResources = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;
  flex-shrink: 0;

  @media only screen and (min-width: 768px) {
    text-align: left;
  }

  li > a {
    color: #ffffff;
  }

  li:not(:first-child) {
    margin-top: 1rem;
  }
`

const Footer = () => {
  const backgroundImage = useStaticQuery(graphql`
    query FOOTER_BACKGROUND_IMAGE {
      file(name: { eq: "paper" }) {
        publicURL
      }
    }
  `)
  return (
    <StyledFooter backgroundImageSrc={backgroundImage?.file?.publicURL}>
      <StyledFooterWrapper>
        <StyledFooterGrid>
          <div>
            <Logo />
            <StyledFooterAdditionalResources>
              <li>
                <Link to="/pages/refer">
                  <strong>Refer &amp; Earn →</strong>
                </Link>
              </li>
              <li>
                <a href="https://support.wildearth.com/">
                  <strong>Help Center →</strong>
                </a>
              </li>
            </StyledFooterAdditionalResources>
          </div>
          <FooterNavigation />
        </StyledFooterGrid>
        <StyledFooterMeta>
          <StyledIconList>
            <li>
              <a href="https://www.facebook.com/wildearthpets">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  fill="none"
                  aria-hidden="true"
                  viewBox="0 0 23 22"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      fill="#fff"
                      d="M22.059 11c0-6.075-4.938-11-11.03-11C4.938 0 0 4.925 0 11c0 5.49 4.033 10.041 9.306 10.866V14.18h-2.8V11h2.8V8.577c0-2.757 1.647-4.28 4.166-4.28 1.206 0 2.469.215 2.469.215v2.707h-1.39c-1.37 0-1.798.848-1.798 1.718V11h3.059l-.49 3.18h-2.57v7.686C18.027 21.041 22.06 16.49 22.06 11z"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <path fill="#fff" d="M0 0H22.059V22H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
                <ScreenReaderText>Wild Earth on Facebook</ScreenReaderText>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/wildearthpets">
                <svg
                  width={20}
                  height={20}
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M19.551 4.208q-.815 1.202-1.956 2.038 0 .082.02.255t.02.255q0 1.589-.469 3.179t-1.426 3.036-2.272 2.567-3.158 1.793-3.963.672q-3.301 0-6.031-1.773.571.041.937.041 2.751 0 4.911-1.671-1.284-.02-2.292-.784T2.456 11.85q.346.082.754.082.55 0 1.039-.163-1.365-.285-2.262-1.365T1.09 7.918v-.041q.774.408 1.773.448-.795-.53-1.263-1.396t-.469-1.864q0-1.019.509-1.997 1.487 1.854 3.596 2.924T9.81 7.184q-.143-.509-.143-.897 0-1.63 1.161-2.781t2.832-1.151q.815 0 1.569.326t1.284.917q1.345-.265 2.506-.958-.428 1.386-1.732 2.18 1.243-.163 2.262-.611z"></path>
                </svg>
                <ScreenReaderText>Wild Earth on Twitter</ScreenReaderText>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/wildearthpets/">
                <svg
                  width={20}
                  height={20}
                  aria-hidden="true"
                  viewBox="0 0 512 512"
                  fill="#fff"
                >
                  <path d="M256 49.5c67.3 0 75.2.3 101.8 1.5 24.6 1.1 37.9 5.2 46.8 8.7 11.8 4.6 20.2 10 29 18.8s14.3 17.2 18.8 29c3.4 8.9 7.6 22.2 8.7 46.8 1.2 26.6 1.5 34.5 1.5 101.8s-.3 75.2-1.5 101.8c-1.1 24.6-5.2 37.9-8.7 46.8-4.6 11.8-10 20.2-18.8 29s-17.2 14.3-29 18.8c-8.9 3.4-22.2 7.6-46.8 8.7-26.6 1.2-34.5 1.5-101.8 1.5s-75.2-.3-101.8-1.5c-24.6-1.1-37.9-5.2-46.8-8.7-11.8-4.6-20.2-10-29-18.8s-14.3-17.2-18.8-29c-3.4-8.9-7.6-22.2-8.7-46.8-1.2-26.6-1.5-34.5-1.5-101.8s.3-75.2 1.5-101.8c1.1-24.6 5.2-37.9 8.7-46.8 4.6-11.8 10-20.2 18.8-29s17.2-14.3 29-18.8c8.9-3.4 22.2-7.6 46.8-8.7 26.6-1.3 34.5-1.5 101.8-1.5m0-45.4c-68.4 0-77 .3-103.9 1.5C125.3 6.8 107 11.1 91 17.3c-16.6 6.4-30.6 15.1-44.6 29.1-14 14-22.6 28.1-29.1 44.6-6.2 16-10.5 34.3-11.7 61.2C4.4 179 4.1 187.6 4.1 256s.3 77 1.5 103.9c1.2 26.8 5.5 45.1 11.7 61.2 6.4 16.6 15.1 30.6 29.1 44.6 14 14 28.1 22.6 44.6 29.1 16 6.2 34.3 10.5 61.2 11.7 26.9 1.2 35.4 1.5 103.9 1.5s77-.3 103.9-1.5c26.8-1.2 45.1-5.5 61.2-11.7 16.6-6.4 30.6-15.1 44.6-29.1 14-14 22.6-28.1 29.1-44.6 6.2-16 10.5-34.3 11.7-61.2 1.2-26.9 1.5-35.4 1.5-103.9s-.3-77-1.5-103.9c-1.2-26.8-5.5-45.1-11.7-61.2-6.4-16.6-15.1-30.6-29.1-44.6-14-14-28.1-22.6-44.6-29.1-16-6.2-34.3-10.5-61.2-11.7-27-1.1-35.6-1.4-104-1.4z"></path>
                  <path d="M256 126.6c-71.4 0-129.4 57.9-129.4 129.4s58 129.4 129.4 129.4 129.4-58 129.4-129.4-58-129.4-129.4-129.4zm0 213.4c-46.4 0-84-37.6-84-84s37.6-84 84-84 84 37.6 84 84-37.6 84-84 84z"></path>
                  <circle cx="390.5" cy="121.5" r="30.2"></circle>
                </svg>
                <ScreenReaderText>Wild Earth on Instagram</ScreenReaderText>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCMrIHFnE3W5f8O7TPmwkhag">
                <svg
                  width={20}
                  height={20}
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  fill="#fff"
                >
                  <path d="M-.196 15.803q0 1.23.812 2.092t1.977.861h14.946q1.165 0 1.977-.861t.812-2.092V3.909q0-1.23-.82-2.116T17.539.907H2.593q-1.148 0-1.969.886t-.82 2.116v11.894zm7.465-2.149V6.058q0-.115.066-.18.049-.016.082-.016l.082.016 7.153 3.806q.066.066.066.164 0 .066-.066.131l-7.153 3.806q-.033.033-.066.033-.066 0-.098-.033-.066-.066-.066-.131z"></path>
                </svg>
                <ScreenReaderText>Wild Earth on YouTube</ScreenReaderText>
              </a>
            </li>
          </StyledIconList>
          <p>Copyright &copy; Wild Earth {new Date().getFullYear()}</p>
        </StyledFooterMeta>
      </StyledFooterWrapper>
    </StyledFooter>
  )
}

export default Footer
