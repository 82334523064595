import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { StyledButton, StyledButtonWrap } from "./StyledButton"

export const StyledCTAButton = styled(StyledButton)`
  margin: 16px 0;
`

function ButtonLink({
  alignment,
  text,
  target,
  rel,
  backgroundColor,
  textColor,
  url,
}) {
  return (
    <StyledButtonWrap
      alignment={alignment}
      backgroundColor={backgroundColor}
      textColor={textColor}
      withLargeRadius
    >
      <StyledCTAButton
        className="call-to-action-button"
        as={Link}
        to={url}
        target={target}
        rel={rel}
      >
        {text}
      </StyledCTAButton>
    </StyledButtonWrap>
  )
}

ButtonLink.propTypes = {
  alignment: PropTypes.oneOf(["left", "right", "center"]),
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
}

ButtonLink.defaultProps = {
  alignment: "left",
}

export default ButtonLink
