import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import { parseHtml } from "../utils/parseHtml"

export const StyledSnackbar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5eeda;
  width: 100%;
  min-height: 48px;
  color: var(--color-great-dane);
  text-align: center;

  * {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: var(--color-great-dane);
  }

  a:focus {
    color: var(--color-great-dane);
  }
`

function Snackbar() {
  const graphqlResult = useStaticQuery(graphql`
    {
      allWpPage {
        nodes {
          id
          snackbar {
            content
            disabled
            fieldGroupName
          }
          slug
        }
      }
      wp(
        acfOptionsGlobalOptions: {
          pageSlug: { eq: "acf-options-global-options" }
        }
      ) {
        id
        acfOptionsGlobalOptions {
          snackbar {
            content
            disabled
            fieldGroupName
          }
        }
      }
    }
  `)
  const globalSnackBar = graphqlResult?.wp.acfOptionsGlobalOptions.snackbar

  const globalSnackbarContent = parseHtml(globalSnackBar.content)

  const location = useLocation()
  const pathname = location.pathname.replaceAll("/", "")
  const pageSnackbar = graphqlResult?.allWpPage.nodes.find(
    page => page.slug === pathname
  )

  const allSnackbarsDisabled =
    !globalSnackBar.disabled &&
    (!pageSnackbar || !pageSnackbar?.snackbar.disabled)

  if (!allSnackbarsDisabled) {
    return (
      <StyledSnackbar>
        {pageSnackbar?.snackbar.content && pageSnackbar?.snackbar.disabled
          ? parseHtml(pageSnackbar.snackbar.content)
          : globalSnackBar.disabled && globalSnackbarContent}
      </StyledSnackbar>
    )
  } else {
    return null
  }
}

export default Snackbar
