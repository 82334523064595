import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import Icon from "../Icon/Icon"
import Portal from "../Portal/Portal"
import {
  StyledModal,
  StyledModalBackdrop,
  StyledModalCloseButton,
  StyledModalInner,
} from "./StyledModal"

const Modal = forwardRef(({ children, title, ...rest }, ref) => {
  const [visible, setVisible] = useState(false)
  const [offsetTop, setOffsetTop] = useState()

  // Enables us ot assign a ref to this modal component from a parent and
  // invoke these functions using ref.current.open()
  //
  // https://levelup.gitconnected.com/accessible-modals-in-gatsby-using-portals-1e80e33a8a2
  useImperativeHandle(ref, () => {
    return {
      open: () => handleOpen(),
      close: () => handleClose(),
    }
  })

  useEffect(() => {
    const offsetTop = document.getElementById("header").clientHeight
    setOffsetTop(offsetTop)

    if (visible) {
      document.querySelector("body").style.overflow = "hidden"
    }

    return () => {
      document.querySelector("body").style = ""
    }
  }, [visible])

  const handleOpen = () => setVisible(true)
  const handleClose = () => setVisible(false)

  if (visible) {
    return (
      <Portal>
        <StyledModalBackdrop onClick={handleClose} />
        <StyledModal {...rest} offsetTop={offsetTop}>
          <StyledModalInner>
            <StyledModalCloseButton onClick={handleClose}>
              <Icon name="close" />
            </StyledModalCloseButton>
            {children}
          </StyledModalInner>
        </StyledModal>
      </Portal>
    )
  }

  return null
})

export default Modal
